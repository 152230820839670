import {
    type Dispatch,
    type FC,
    Fragment,
    type MutableRefObject,
    type ReactElement,
    type SetStateAction,
    useEffect,
    useRef,
    useState
} from "react"

import { CSSTransition } from "react-transition-group"

import { isEmpty } from "$/utils/gates"

import {
    ESpinnerSize,
    ETypographyColor,
    ETypographyFontWeight,
    ETypographySize,
    Spinner,
    Typography
} from "@/3514/components"
import { getCssIdentifierFromFileName, getScaleAnimationCssString } from "@/3514/utils"
import { type IUseTranslation, useCSSInsertion, useTranslation } from "@/hooks"
import { ERichTextRendererMode, RichTextRenderer } from "@/shared/rich-text-renderer"
import { type TEmptyCallback, emptyCallback } from "@/shared/types/functions"

import { participantCoachingMomentChatUiConfig as uiConfig } from "../config"
import { useParticipantCoachingMomentChatContext as useChatContext } from "../context"
import { EParticipantCoachingMomentChatStatus as EChatStatus } from "../types"
import { participantCoachingMomentChatUiUtils as utils } from "../utils"

import { ParticipantCoachingMomentChatClosedIndicator as ClosedIndicator } from "./participant-coaching-moment-chat-closed-indicator.component"

const { getChatSummaryClassNamesStringForInsertion: getClassNamesStringForInsertion } = utils

const displayName: string = "ParticipantCoachingMomentChatSummary"

const editorClassNamesIdentifier: string = getCssIdentifierFromFileName(displayName, "ed")

const generationBoxCssAnimationIdentifier: string = getCssIdentifierFromFileName(displayName, "gb")
const summaryBoxCssAnimationIdentifier: string = getCssIdentifierFromFileName(displayName, "sm")

const classes: { generatingSummaryBox: string; summaryWrapper: string; summary: string } = {
    generatingSummaryBox: "bg-white/30 rounded-[10px] py-[10px] px-[20px] my-[7px] mx-[40px] w-fit mx-auto",
    summaryWrapper: "flex flex-col",
    summary: "py-[15px] px-[20px] rounded-[10px] bg-white/40 max-w-[600px] mx-auto my-[7px]"
}

const Summary: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const {
        chat: { summary, status, changeStatus }
    }: ReturnType<typeof useChatContext> = useChatContext()

    const [isGenerationBoxVisible, setIsGenerationBoxVisible]: [boolean, Dispatch<SetStateAction<boolean>>] =
        useState<boolean>(false)

    const shouldSummaryBeGenerating: boolean = status === EChatStatus.GeneratingSummary

    const isChatCompleted: boolean = status === EChatStatus.Completed && !isEmpty(summary)

    useEffect((): TEmptyCallback => {
        if (
            !shouldSummaryBeGenerating ||
            [EChatStatus.InProgress, EChatStatus.Ready, EChatStatus.Paused].includes(status)
        ) {
            if (!shouldSummaryBeGenerating) {
                changeStatus(EChatStatus.Completed)
            }
            return emptyCallback
        }

        setIsGenerationBoxVisible(true)
        const timeout: ReturnType<typeof setTimeout> = setTimeout(
            (): void => (setIsGenerationBoxVisible(false), changeStatus(EChatStatus.Completed), void 0),
            uiConfig.SUMMARY_GENERATION_DURATION_IN_MS
        )

        return (): void => (clearTimeout(timeout), void 0)
    }, [shouldSummaryBeGenerating, status, changeStatus])

    const generationSummaryRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
    const summaryContentRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

    useCSSInsertion({
        cssString: getScaleAnimationCssString({
            identifier: generationBoxCssAnimationIdentifier,
            durationInMs: 250,
            scaleTo: 0.8
        })
    })
    useCSSInsertion({
        cssString: getScaleAnimationCssString({
            identifier: summaryBoxCssAnimationIdentifier,
            durationInMs: 400,
            scaleTo: 0.7
        })
    })

    return (
        <Fragment>
            <CSSTransition
                timeout={250}
                in={isGenerationBoxVisible && !isEmpty(summary)}
                nodeRef={generationSummaryRef}
                classNames={generationBoxCssAnimationIdentifier}
                mountOnEnter
                unmountOnExit
            >
                <div ref={generationSummaryRef} className={classes.generatingSummaryBox}>
                    <Typography
                        text={t("participantSide.chapter.coachingMoment.chat.summaryGenerationMessage")}
                        size={ETypographySize.Tiny}
                        weight={ETypographyFontWeight.Bold}
                        color={ETypographyColor.Dark}
                    />
                </div>
            </CSSTransition>
            <CSSTransition
                timeout={400}
                in={!isGenerationBoxVisible && isChatCompleted}
                nodeRef={summaryContentRef}
                classNames={summaryBoxCssAnimationIdentifier}
                mountOnEnter
                unmountOnExit
            >
                <div ref={summaryContentRef} className={classes.summaryWrapper}>
                    <div className={classes.summary}>
                        <RichTextRenderer
                            fallbackLoader={<Spinner size={ESpinnerSize.Small} />}
                            content={summary}
                            mode={ERichTextRendererMode.View}
                            isEditorForceSkipped
                            cssConfig={{
                                editorClassName: "!z-[1]",
                                classNameIdentifier: editorClassNamesIdentifier,
                                classNamesToOverride: getClassNamesStringForInsertion(editorClassNamesIdentifier)
                            }}
                        />
                    </div>
                    <ClosedIndicator />
                </div>
            </CSSTransition>
        </Fragment>
    )
}

Summary.displayName = displayName

export { Summary as ParticipantCoachingMomentChatSummary }
