enum EQueryKey {
    CoachingMomentChatConversation = "CoachingMomentChatConversation",
    CoachingMomentChatNewMessages = "CoachingMomentChatNewMessages"
}

const EQueryUrl: {
    GetConversation(conversationId: number): string
    GetConversationNewMessages(conversationId: number): string
} = {
    GetConversation: (conversationId: number): string => `/api/practice-lab/conversation/${conversationId}/`,
    GetConversationNewMessages: (conversationId: number): string =>
        `/api/practice-lab/conversation/${conversationId}/new-messages/`
}

enum EMutationKey {
    CreateCoachingMomentChatSeedQuestionAnswer = "createCoachingMomentChatSeedQuestionAnswer",
    CreateCoachingMomentChatMessage = "createCoachingMomentChatMessage",
    CreateCoachingMomentChatFeedback = "createCoachingMomentChatFeedback"
}

const EMutationUrl: {
    CreateSeedQuestionAnswer(): string
    CreateMessage(conversationId: number): string
    CreateFeedback(): string
} = {
    CreateSeedQuestionAnswer: (): string => "/api/practice-lab/conversation/seed-question-answer/",
    CreateMessage: (conversationId: number): string => `/api/practice-lab/conversation/${conversationId}/message/`,
    CreateFeedback: (): string => "/api/coaching-moment/feedback/"
}

const NEW_MESSAGES_QUERY_SHORT_POLLING_REFETCH_INTERVAL_IN_MS: number = 4000
const NEW_MESSAGES_QUERY_LONG_POLLING_REFETCH_INTERVAL_IN_MS: number = 30000

export {
    EQueryKey as EParticipantCoachingMomentChatApiQueryKey,
    EMutationKey as EParticipantCoachingMomentChatApiMutationKey,
    EQueryUrl as EParticipantCoachingMomentChatApiQueryUrl,
    EMutationUrl as EParticipantCoachingMomentChatApiMutationUrl,
    NEW_MESSAGES_QUERY_SHORT_POLLING_REFETCH_INTERVAL_IN_MS,
    NEW_MESSAGES_QUERY_LONG_POLLING_REFETCH_INTERVAL_IN_MS
}
