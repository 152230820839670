import type { TranslationObj } from "$/utils/lang"

import { EModulePhaseOrChapterStatus } from "@/3514/types"

import { EModulePhaseOrChapterUiStatus } from "./module-phase-or-chapter-ui-status.model.types"

enum EModulePhaseChapterType {
    Access = "access",
    Learn = "learn",
    Prepare = "prepare",
    Reflect = "reflect",
    ReflectFinal = "reflect-final"
}

interface IModulePhaseChapterTypeModel {
    id: number
    internalName: string
    slug: EModulePhaseChapterType
    title: TranslationObj
}

interface IModulePhaseChapterModel {
    id: number
    order: number
    summary: TranslationObj | string
    title: TranslationObj
    type: IModulePhaseChapterTypeModel
    status: EModulePhaseOrChapterStatus
    progress: number
    uiStatus: EModulePhaseOrChapterUiStatus
}

export type { IModulePhaseChapterModel, IModulePhaseChapterTypeModel }
export { EModulePhaseChapterType }
