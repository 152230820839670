import type { BaseSyntheticEvent, FC, KeyboardEvent, MouseEvent, ReactElement } from "react"

import classNames from "classnames"

import { ETypographyColor, ETypographyFontWeight, ETypographySize, ETypographyTag, Typography } from "@/3514/components"
import { getTransitionTwClasses } from "@/3514/utils"
import { type IUseTranslation, type TUseManualHover, useManualHover, useTranslation } from "@/hooks"

import { useParticipantCoachingMomentChatContext as useChatContext } from "../context"
import { ECoachingMomentChatModalFeedbackVariant as EModalVariant } from "../types"

const classes: { container(): string; badge(isActive: boolean): string; betaWrapper(): string } = {
    container: (): string =>
        [
            "absolute",
            "w-full",
            "rounded-tl-[10px]",
            "rounded-tr-[10px]",
            "top-[0px]",
            "left-[0px]",
            "bg-chat-title",
            "flex",
            "gap-x-[10px]",
            "pt-[15px]",
            "pb-[10px]",
            "px-[15px]",
            "backdrop-blur-[2px]",
            "z-[1]"
        ].join(" "),
    betaWrapper: (): string => classNames("hover:scale-110 focus:scale-110 cursor-pointer", getTransitionTwClasses()),
    badge: (isActive: boolean): string =>
        classNames(
            "py-[2px] px-[5px] rounded-[5px]",
            getTransitionTwClasses("colors"),
            isActive ? "bg-white" : "bg-accent"
        )
}

const Heading: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const {
        modal: { handleModalOpen }
    }: ReturnType<typeof useChatContext> = useChatContext()

    const { onMouseEnter, onMouseLeave, onFocus, onBlur, isActionAffected }: TUseManualHover = useManualHover()

    const handleBetaAction: (e?: BaseSyntheticEvent) => void = (e: KeyboardEvent & MouseEvent): void =>
        (e.key === "Enter" || e.type === "click") && handleModalOpen(EModalVariant.General)

    return (
        <div className={classes.container()}>
            <Typography
                text={t("participantSide.chapter.coachingMoment.chat.topLabel")}
                color={ETypographyColor.White}
                size={ETypographySize.Small}
                weight={ETypographyFontWeight.Bold}
            />
            <div
                role="tab"
                tabIndex={0}
                className={classes.betaWrapper()}
                onKeyDown={handleBetaAction}
                onClick={handleBetaAction}
                onFocus={onFocus}
                onBlur={onBlur}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <Typography
                    text="BETA"
                    color={isActionAffected ? ETypographyColor.Accent : ETypographyColor.White}
                    weight={ETypographyFontWeight.Bold}
                    tag={ETypographyTag.Paragraph}
                    size={ETypographySize.Tiny}
                    className={classes.badge(isActionAffected)}
                />
            </div>
        </div>
    )
}

Heading.displayName = "ParticipantCoachingMomentChatHeading"

export { Heading as ParticipantCoachingMomentChatHeading }
