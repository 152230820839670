import {
    type ForwardRefExoticComponent,
    type ForwardedRef,
    type HTMLAttributes,
    type ReactElement,
    forwardRef,
    isValidElement
} from "react"

import classNames from "classnames"

import { ESpinnerVariant, Typography } from "@/3514/components"

import { ESpinnerSize, Spinner } from "../spinner"

import { buttonConfig } from "./button.config"
import { EButtonSize, EButtonVariant } from "./button.types"

type TProps = Partial<HTMLAttributes<HTMLButtonElement>> &
    Omit<HTMLAttributes<HTMLButtonElement>, "disabled"> & {
        variant?: EButtonVariant
        size?: EButtonSize
        isBusy?: boolean
        isDisabled?: boolean
        isCentered?: boolean
    }

const Component: ForwardRefExoticComponent<TProps> = forwardRef<HTMLButtonElement, TProps>(
    (
        {
            className = String(),
            variant = EButtonVariant.Solid,
            size = EButtonSize.Medium,
            isDisabled = false,
            isBusy = false,
            onClick,
            children,
            isCentered = false,
            ...rest
        }: TProps,
        ref: ForwardedRef<HTMLButtonElement>
    ): ReactElement => (
        <button
            className={classNames(className, {
                [buttonConfig.baseClasses]: true,
                [buttonConfig.sizeMap[size]]: size,
                [buttonConfig.colorMap[variant](isDisabled)]: variant,
                "mx-auto": isCentered
            })}
            onClick={onClick}
            disabled={isBusy || isDisabled}
            aria-disabled={isBusy || isDisabled}
            ref={ref}
            type="submit"
            {...rest}
        >
            {isBusy ? (
                <div className={classNames("w-100 h-100", size === EButtonSize.Medium && "py-[2px]")}>
                    <Spinner
                        variant={variant === EButtonVariant.Solid ? ESpinnerVariant.White : ESpinnerVariant.Accent}
                        size={ESpinnerSize.Small}
                    />
                </div>
            ) : isValidElement(children) ? (
                children
            ) : (
                <Typography text={children} {...buttonConfig.getTypographyProps(size, variant, isDisabled)} />
            )}
        </button>
    )
)

Component.displayName = "Button"

export { Component as Button, type TProps as TButtonProps }
