import classNames from "classnames"

import { getTransitionTwClasses } from "@/3514/utils"

import {
    ELikertColorSeverity,
    ELikertColorVariant,
    ELikertOptionPosition,
    ELikertOptionSize,
    ELikertOptionState
} from "./likert.types"

const baseOptionClasses: string = classNames(
    "group cursor-pointer flex p-[10px] items-center justify-center",
    getTransitionTwClasses("colors")
)

const baseIndicatorClasses: string = classNames(
    "block pointer-events-none w-[15px] h-[15px] rounded-full",
    getTransitionTwClasses("colors")
)

const optionColorMap: {
    [V in ELikertColorVariant]: (
        state: ELikertOptionState,
        severity?: ELikertColorSeverity
    ) => { option(): string; indicator(): string }
} = {
    [ELikertColorVariant.Default]: (
        state: ELikertOptionState,
        _severity?: ELikertColorSeverity
    ): { option(): string; indicator(): string } => ({
        option: (): string =>
            classNames(
                state === ELikertOptionState.Default && "bg-blue-600 focus:bg-blue-400",
                state === ELikertOptionState.ActionAffected && "bg-blue-400 focus:bg-blue-300",
                state === ELikertOptionState.Selected && "bg-blue-300 focus:bg-blue-300"
            ),
        indicator: (): string =>
            classNames(
                state === ELikertOptionState.Default && "bg-blue-300 group-focus:bg-blue-200",
                state === ELikertOptionState.ActionAffected && "bg-blue-200 group-focus:bg-blue-100",
                state === ELikertOptionState.Selected && "bg-blue-100 group-focus:bg-blue-100"
            )
    }),
    [ELikertColorVariant.Colored]: (
        state: ELikertOptionState,
        severity?: ELikertColorSeverity
    ): { option(): string; indicator(): string } => ({
        option: (): string => {
            switch (severity) {
                case ELikertColorSeverity.Success:
                    return classNames(
                        state === ELikertOptionState.Default && "bg-white",
                        state === ELikertOptionState.ActionAffected && "bg-green-300",
                        state === ELikertOptionState.Selected && "bg-green-100"
                    )
                case ELikertColorSeverity.Warning:
                    return classNames(
                        state === ELikertOptionState.Default && "bg-white",
                        state === ELikertOptionState.ActionAffected && "bg-yellow-200",
                        state === ELikertOptionState.Selected && "bg-yellow-100"
                    )
                case ELikertColorSeverity.Danger:
                    return classNames(
                        state === ELikertOptionState.Default && "bg-white",
                        state === ELikertOptionState.ActionAffected && "bg-red-300",
                        state === ELikertOptionState.Selected && "bg-red-200"
                    )
                default:
                    return "bg-white"
            }
        },
        indicator: (): string =>
            classNames(
                state === ELikertOptionState.Default && "bg-blue-300",
                state === ELikertOptionState.ActionAffected && "bg-black bg-opacity-30",
                state === ELikertOptionState.Selected && "bg-black bg-opacity-40"
            )
    })
}

const optionPositionMap: { [P in ELikertOptionPosition]: string } = {
    [ELikertOptionPosition.Left]: "rounded-tl-[10px] rounded-tr-[5px] rounded-br-[5px] rounded-bl-[10px]",
    [ELikertOptionPosition.Middle]: "rounded-[5px]",
    [ELikertOptionPosition.Right]: "rounded-tl-[5px] rounded-tr-[10px] rounded-br-[10px] rounded-bl-[5px]"
}

const optionSizeMap: { [S in ELikertOptionSize]: string } = {
    [ELikertOptionSize.MinWidth]: "w-min",
    [ELikertOptionSize.FullWidth]: "w-full"
}

const config = {
    baseIndicatorClasses,
    baseOptionClasses,
    optionColorMap,
    optionSizeMap,
    optionPositionMap
}

export { config as likertConfig }
