import { type Dispatch, useCallback, useReducer } from "react"

import type { TEmptyCallback } from "@/shared/types/functions"

import {
    EParticipantCoachingMomentChatStatus as EChatStatus,
    type IParticipantCoachingMomentChatMessageModel as IConversationMessageModel,
    type IParticipantCoachingMomentChatConversationModel as IConversationModel,
    type IParticipantCoachingMomentChatNewMessagesModel as INewMessagesModel,
    type TParticipantCoachingMomentSeedQuestionFormField as TQuestionFormField
} from "../types"

import {
    EParticipantCoachingMomentChatActionType as EActionType,
    type TParticipantCoachingMomentChatAction as TAction,
    type TParticipantCoachingMomentChatState as TState,
    participantCoachingMomentChatInitialState as initialState,
    participantCoachingMomentChatReducer as reducer
} from "./participant-coaching-moment-chat.state.reducer"

type THook = {
    state: TState
    loadQuestionsAnswers(questions: TQuestionFormField[]): void
    setQuestionAnswer(payload: Pick<TQuestionFormField, "id" | "answer">): void
    setConversationId(id: number): void
    loadConversation({ conversation, status }: { conversation: IConversationModel; status?: EChatStatus }): void
    addNewMessages(message: INewMessagesModel): void
    sendMessage({
        message,
        optimisticMessageId,
        responseMessageId
    }: {
        message?: IConversationMessageModel
        optimisticMessageId?: number
        responseMessageId?: number
    }): void
    removeMessage(id: number): void
    changeStatus(status: EChatStatus): void
    triggerError: TEmptyCallback
}

function useHook(): ReturnType<() => THook> {
    const [state, dispatch]: [TState, Dispatch<TAction>] = useReducer(reducer, initialState)

    const loadQuestionsAnswers: (questions: TQuestionFormField[]) => void = useCallback(
        (questions: TQuestionFormField[]): void =>
            dispatch({ type: EActionType.LOAD_QUESTIONS_ANSWERS, payload: questions }),
        []
    )

    const setQuestionAnswer: ({ id, answer }: Pick<TQuestionFormField, "id" | "answer">) => void = useCallback(
        (payload: Pick<TQuestionFormField, "id" | "answer">): void =>
            dispatch({ type: EActionType.SUBMIT_QUESTION, payload }),
        []
    )

    const setConversationId: (id: number) => void = useCallback(
        (id: number): void => dispatch({ type: EActionType.SET_CONVERSATION_ID, payload: id }),
        []
    )

    const loadConversation: ({
        conversation,
        status
    }: {
        conversation: IConversationModel
        status?: EChatStatus
    }) => void = useCallback(
        ({ conversation, status }: { conversation: IConversationModel; status?: EChatStatus }): void =>
            dispatch({ type: EActionType.LOAD_CONVERSATION, payload: { conversation, status } }),
        []
    )

    const addNewMessages: (message: INewMessagesModel) => void = useCallback(
        (message: INewMessagesModel): void => dispatch({ type: EActionType.ADD_MESSAGES, payload: message }),
        []
    )

    const sendMessage: ({
        message,
        optimisticMessageId,
        responseMessageId
    }: {
        message?: IConversationMessageModel
        optimisticMessageId?: number
        responseMessageId?: number
    }) => void = useCallback(
        ({
            message,
            optimisticMessageId,
            responseMessageId
        }: {
            message?: IConversationMessageModel
            optimisticMessageId?: number
            responseMessageId?: number
        }): void =>
            dispatch({ type: EActionType.SEND_MESSAGE, payload: { message, optimisticMessageId, responseMessageId } }),
        []
    )

    const removeMessage: (msgId: number) => void = useCallback(
        (msgId: number): void => dispatch({ type: EActionType.REMOVE_MESSAGE, payload: msgId }),
        []
    )

    const changeStatus: (status: EChatStatus) => void = useCallback(
        (status: EChatStatus): void => dispatch({ type: EActionType.CHANGE_STATUS, payload: status }),
        []
    )

    const triggerError: TEmptyCallback = useCallback((): void => dispatch({ type: EActionType.TRIGGER_ERROR }), [])

    return {
        state,
        loadQuestionsAnswers,
        setQuestionAnswer,
        setConversationId,
        loadConversation,
        addNewMessages,
        sendMessage,
        removeMessage,
        changeStatus,
        triggerError
    }
}

export { useHook as useParticipantCoachingMomentChatState }
export type { THook as TUseParticipantCoachingMomentChatState }
