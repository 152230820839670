import { type FC, type ReactElement } from "react"

import { ETypographyAlignment, ETypographySize, Typography } from "@/3514/components"
import { type IUseTranslation, useTranslation } from "@/hooks"

import { useParticipantCoachingMomentChatContext as useChatContext } from "../context"
import { EParticipantCoachingMomentChatStatus as EChatStatus } from "../types"

const classes: { wrapper: string } = {
    wrapper: "mx-auto absolute top-[120px] max-w-[250px] select-none self-center "
}

const Message: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const {
        chat: { status }
    }: ReturnType<typeof useChatContext> = useChatContext()

    const isVisible: boolean = status === EChatStatus.InProgress

    return (
        isVisible && (
            <div className={classes.wrapper}>
                <Typography
                    text={t("participantSide.chapter.coachingMoment.chat.unstartedMessage")}
                    size={ETypographySize.Small}
                    alignment={ETypographyAlignment.Center}
                    isItalic
                />
            </div>
        )
    )
}

Message.displayName = "ParticipantCoachingMomentChatUnstartedLabel"

export { Message as ParticipantCoachingMomentChatUnstartedLabel }
