import classNames from "classnames"

import { getTransitionTwClasses } from "@/3514/utils"

import { ESelectSize, ESelectVariant } from "./select.types"

const baseClasses: {
    container(): string
    wrapper(): string
    icon(isSelectOpen: boolean): string
    optionText(): string
    emptyOptionsText(): string
    spinner(): string
} = {
    container: (): string =>
        "flex items-center flex-col w-full md:flex-row gap-y-[10px] md:gap-y-[0px] md:gap-x-[20px]",
    wrapper: (): string => "relative",
    icon: (isSelectOpen: boolean): string => classNames("transition-all duration-200", isSelectOpen && "rotate-180"),
    optionText: (): string => "select-none pointer-events-none",
    emptyOptionsText: (): string => "block mx-[20px] my-[5px]",
    spinner: (): string => "my-[20px]"
}

const variantClassesMap: {
    [V in ESelectVariant]: {
        wrapper(isOpen: boolean, isDisabled: boolean, hasInitialValue: boolean): string
        container(): string
        option(wasSelected: boolean, isActive: boolean, isDisabled: boolean): string
    }
} = {
    [ESelectVariant.Default]: {
        wrapper: (isOpen: boolean, isDisabled: boolean, hasInitialValue: boolean): string =>
            classNames(
                ["flex", "items-center", "justify-between", "bg-white", "border-[2px]", "px-[20px]", "py-[8px]"],
                getTransitionTwClasses(),
                isDisabled
                    ? ["cursor-not-allowed border-gray-400"]
                    : [
                          "cursor-pointer",
                          hasInitialValue
                              ? "hover:border-blue-300 focus:border-blue-300"
                              : "hover:border-gray-400 focus:border-gray-400"
                      ],
                isOpen
                    ? "border-blue-300 rounded-tl-[10px] rounded-tr-[10px] rounded-bl-[0px] rounded-br-[0px]"
                    : [hasInitialValue ? "border-gray-400 rounded-[10px]" : "border-blue-400 rounded-[10px]"]
            ),
        container: (): string =>
            [
                "absolute",
                "mt-[0px]",
                "bg-white",
                "border-[1px]",
                "border-b-blue-400",
                "border-r-blue-400",
                "border-l-blue-400",
                "!border-t-transparent",
                "rounded-tl-[0px]",
                "rounded-tr-[0px]",
                "rounded-bl-[10px]",
                "rounded-br-[10px]",
                "z-10"
            ].join(" "),
        option: (wasSelected: boolean, isActive: boolean, isDisabled: boolean): string => {
            return classNames(
                [
                    "px-[20px]",
                    "py-[8px]",
                    "first:rounded-br-[0px]",
                    "first:rounded-bl-[0px]",
                    "last:rounded-br-[10px]",
                    "last:rounded-bl-[10px]"
                ],
                getTransitionTwClasses(),
                wasSelected && "bg-blue-400",
                isActive && "bg-blue-500",
                isDisabled ? "cursor-not-allowed bg-gray-400" : "cursor-pointer hover:bg-blue-400 focus:bg-blue-400"
            )
        }
    }
}

const sizeClassesMap: { [S in ESelectSize]: { wrapper(): string; container(): string } } = {
    [ESelectSize.Medium]: {
        wrapper: (): string => "max-w-[300px] w-full",
        container: (): string => "max-w-[300px] w-full"
    },
    [ESelectSize.Full]: { wrapper: (): string => "w-full", container: (): string => "w-full" }
}

const config = { baseClasses, variantClassesMap, sizeClassesMap }

export { config as selectConfig }
