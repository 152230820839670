import type { TranslationObj } from "$/utils/lang"

enum EChapterComponentType {
    QuestionGroup = "questionGroup",
    VideoVimeo = "videoVimeo",
    Content = "content",
    ConversationScenarios = "conversationScenarios",
    CoachingMoment = "coachingMomentChat",
    TrackPractice = "trackPractice"
}

type _TChapterComponentBaseModel = {
    id: number
    uuid: string
    order: number
    isPublished: boolean
    isRequired: boolean
    type: EChapterComponentType
}

// conversation scenarios --start
interface IChapterConversationScenarioModel {
    id: number
    internalName: string
    description: string
    conversationId?: number | null
    isStarted: boolean
    isCompleted: boolean
    coach: { id: number; name: string; photo: string | null }
}

interface IChapterConversationScenariosComponentModel extends _TChapterComponentBaseModel {
    title: TranslationObj
    conversationScenarios: IChapterConversationScenarioModel[]
}
// conversation scenarios --end

// video --start
interface IChapterVideoComponentModel extends _TChapterComponentBaseModel {
    videoId: string
    title: TranslationObj
}
// video --end

// content --start
interface IChapterContentComponentModel extends _TChapterComponentBaseModel {
    content: TranslationObj
    title: TranslationObj
}
// content --end

// likert --start
interface IChapterLikertComponentQuestionOptionModel {
    id: number
    response: TranslationObj
    isFreeResponseAllowed: boolean
    freeResponsePrompt: TranslationObj
}

interface IChapterLikertComponentQuestionModel {
    id: number
    order: number
    question: TranslationObj
    options: IChapterLikertComponentQuestionOptionModel[]
    isNotApplicable: boolean
    notApplicableInput?: string
}

interface IChapterLikertComponentModel extends _TChapterComponentBaseModel {
    title: TranslationObj
    questionList: IChapterLikertComponentQuestionModel[]
    summaryTitle: TranslationObj | null
    summaryContent: TranslationObj | null
    showSummaryAfterCompletion: boolean
}
// likert --end

// coaching moments --start
interface IChapterCoachingMomentSeedQuestionModel {
    questionUuid: string
    question: string
}

interface IChapterCoachingMomentAnswerDataModel {
    conversationId: number
    seedQuestions: (IChapterCoachingMomentSeedQuestionModel & { answer: string })[]
}

interface IChapterCoachingMomentComponentModel extends _TChapterComponentBaseModel {
    isChatAvailable: boolean
    seedQuestions: IChapterCoachingMomentSeedQuestionModel[]
    answerData: IChapterCoachingMomentAnswerDataModel
}
// coaching moment --end

// track practice --start
interface IChapterTrackPracticeComponentModel extends _TChapterComponentBaseModel {}
// track practice --end

type IChapterComponentModel =
    | IChapterVideoComponentModel
    | IChapterContentComponentModel
    | IChapterLikertComponentModel
    | IChapterConversationScenariosComponentModel
    | IChapterCoachingMomentComponentModel
    | IChapterTrackPracticeComponentModel

enum EChapterStatus {
    InProgress = "in_progress",
    Completed = "completed"
}

interface IChapterModel {
    id: number
    uuid: string
    enrollmentId: number
    phaseProgressId: number
    moduleTitle: TranslationObj
    title: TranslationObj
    components: IChapterComponentModel[]
    phaseOrder: number
    previousChapterId: number | null
    status: EChapterStatus
    nextChapterId: number | null
    // is the only returned field if chapter is not accessible
    redirectChapterId?: number | null
}

export type {
    IChapterContentComponentModel,
    IChapterLikertComponentModel,
    IChapterModel,
    IChapterLikertComponentQuestionModel,
    IChapterLikertComponentQuestionOptionModel,
    IChapterVideoComponentModel,
    IChapterComponentModel,
    IChapterConversationScenarioModel,
    IChapterConversationScenariosComponentModel,
    IChapterCoachingMomentComponentModel,
    IChapterCoachingMomentSeedQuestionModel,
    IChapterCoachingMomentAnswerDataModel,
    IChapterTrackPracticeComponentModel
}
export { EChapterComponentType, EChapterStatus }
