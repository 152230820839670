import { useCallback, useMemo } from "react"

import { type MutationOptions, type UseMutationResult, useMutation } from "@tanstack/react-query"
import type { AxiosError, AxiosResponse } from "axios"

import { EParticipantCoachingMomentChatApiMutationKey as EMutationKey } from "../../../config"
import {
    EParticipantCoachingMomentChatFeedbackSeverity as EFeedbackSeverity,
    EParticipantCoachingMomentChatFeedbackType as EFeedbackType
} from "../../../types"

import { participantCoachingMomentChatCreateFeedbackFetcher as fetcher } from "./participant-coaching-moment-chat.create-feedback.fetcher"

type TParams = {
    text: string
    context: keyof typeof EFeedbackType
    message_id?: number
    severity?: keyof typeof EFeedbackSeverity
}

type TResponse = AxiosResponse<{}>

type TMutation = UseMutationResult<TResponse>

function useHook(options?: MutationOptions): ReturnType<() => TMutation> {
    return useMutation<TResponse, AxiosError, TParams>(
        useMemo((): [EMutationKey] => [EMutationKey.CreateCoachingMomentChatFeedback], []),
        useCallback(fetcher, []),
        // @ts-expect-error react-query types mess here, no error
        { ...options }
    )
}

export {
    useHook as useParticipantCoachingMomentChatCreateFeedbackMutation,
    type TMutation as TParticipantCoachingMomentChatCreateFeedbackMutation,
    type TParams as TParticipantCoachingMomentChatCreateFeedbackMutationParams,
    type TResponse as TParticipantCoachingMomentChatCreateFeedbackMutationResponse
}
