import { yupResolver } from "@hookform/resolvers/yup"
import { type ObjectSchema } from "yup"
import * as yup from "yup"

import { EParticipantCoachingMomentChatFeedbackModalFormField } from "../types"

function _getSchema(): ObjectSchema {
    return yup
        .object()
        .shape({
            [EParticipantCoachingMomentChatFeedbackModalFormField.Feedback]: yup
                .string()
                .matches(/^(?!\s*$)/)
                .nullable()
                .notRequired()
                .min(1)
                .max(2048),
            [EParticipantCoachingMomentChatFeedbackModalFormField.Severity]: yup.string().nullable().notRequired(),
            [EParticipantCoachingMomentChatFeedbackModalFormField.Context]: yup.string().required()
        })
        .strict(true)
        .noUnknown(true)
}

const resolver: ReturnType<typeof yupResolver> = yupResolver(_getSchema())

export { resolver as participantCoachingMomentChatFeedbackModalFormResolver }
