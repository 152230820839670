import { isEmpty, isEqual, isNumber } from "$/utils/gates"

import type {
    IChapterCoachingMomentComponentDto,
    IChapterCoachingMomentSeedQuestionDto,
    IChapterComponentDto,
    IChapterContentComponentDto,
    IChapterConversationScenarioDto,
    IChapterConversationScenariosComponentDto,
    IChapterDto,
    IChapterLikertComponentDto,
    IChapterLikertComponentQuestionDto,
    IChapterLikertComponentQuestionOptionDto,
    IChapterTrackPracticeComponentDto,
    IChapterVideoComponentDto,
    TChapterComponentType,
    TChapterStatus
} from "@/pages/participant-chapter/api/dto"
import {
    EChapterComponentType,
    EChapterStatus,
    type IChapterCoachingMomentComponentModel,
    type IChapterCoachingMomentSeedQuestionModel,
    type IChapterComponentModel,
    type IChapterContentComponentModel,
    type IChapterConversationScenarioModel,
    type IChapterConversationScenariosComponentModel,
    type IChapterLikertComponentModel,
    type IChapterLikertComponentQuestionModel,
    type IChapterLikertComponentQuestionOptionModel,
    type IChapterModel,
    type IChapterTrackPracticeComponentModel,
    type IChapterVideoComponentModel
} from "@/pages/participant-chapter/models"

function _getChapterComponentEnumFromType(type: TChapterComponentType): EChapterComponentType {
    switch (type) {
        case "question_group":
            return EChapterComponentType.QuestionGroup
        case "video_vimeo":
            return EChapterComponentType.VideoVimeo
        case "content":
            return EChapterComponentType.Content
        case "conversation_scenarios":
            return EChapterComponentType.ConversationScenarios
        case "coaching_moment_chat":
            return EChapterComponentType.CoachingMoment
        case "track_practice":
            return EChapterComponentType.TrackPractice
        case null:
        default:
            return null
    }
}

function _getContentComponentModel(
    model: IChapterComponentModel,
    { content, title }: IChapterContentComponentDto
): IChapterContentComponentModel {
    return { ...model, content, title }
}

function _getVideoComponentModel(
    model: IChapterComponentModel,
    { video_id: videoId, title }: IChapterVideoComponentDto
): IChapterVideoComponentModel {
    return { ...model, videoId, title }
}

function _getCoachingMomentComponentModel<TDto extends IChapterCoachingMomentComponentDto>(
    model: IChapterComponentModel,
    { is_chat_available: isChatAvailable, seed_questions, answer_data }: TDto
): IChapterCoachingMomentComponentModel {
    return {
        ...model,
        isChatAvailable,
        seedQuestions: isEmpty(seed_questions)
            ? []
            : seed_questions.map(
                  ({
                      question_uuid: questionUuid,
                      question
                  }: IChapterCoachingMomentSeedQuestionDto): IChapterCoachingMomentSeedQuestionModel => ({
                      questionUuid,
                      question
                  })
              ),
        answerData: isEmpty(answer_data)
            ? undefined
            : {
                  conversationId: answer_data.conversation,
                  seedQuestions: isEmpty(answer_data?.seed_questions_data)
                      ? []
                      : answer_data.seed_questions_data.map(
                            ({
                                question_uuid: questionUuid,
                                question,
                                answer
                            }: IChapterCoachingMomentSeedQuestionDto & {
                                answer: string
                            }): IChapterCoachingMomentSeedQuestionModel & { answer: string } => ({
                                questionUuid,
                                question,
                                answer
                            })
                        )
              }
    }
}

function _getConversationScenariosComponentModel(
    model: IChapterComponentModel,
    { conversation_scenarios, title }: IChapterConversationScenariosComponentDto
): IChapterConversationScenariosComponentModel {
    return {
        title,
        conversationScenarios: conversation_scenarios.map(
            ({
                id,
                internal_name: internalName,
                conversation_id: conversationId,
                started: isStarted,
                completed: isCompleted,
                description,
                coachee
            }: IChapterConversationScenarioDto): IChapterConversationScenarioModel => ({
                id,
                internalName,
                conversationId,
                isCompleted,
                isStarted,
                description,
                coach: isEmpty(coachee) ? null : { id: coachee.id, name: coachee.name, photo: coachee.photo }
            })
        ),
        ...model
    }
}

function _getLikertComponentModel(
    model: IChapterComponentModel,
    {
        title,
        question_list,
        summary_title: summaryTitle,
        summary_content: summaryContent,
        show_summary_after_completion: showSummaryAfterCompletion
    }: IChapterLikertComponentDto
): IChapterLikertComponentModel {
    return {
        ...model,
        title,
        summaryTitle,
        summaryContent,
        showSummaryAfterCompletion,
        questionList: question_list.reduce<IChapterLikertComponentQuestionModel[]>(
            (
                acc: IChapterLikertComponentQuestionModel[],
                {
                    id,
                    question,
                    order,
                    options,
                    not_applicable,
                    not_applicable_input: notApplicableInput
                }: IChapterLikertComponentQuestionDto
            ): IChapterLikertComponentQuestionModel[] => {
                const questionModel: IChapterLikertComponentQuestionModel = {
                    id,
                    question,
                    order,
                    isNotApplicable: Boolean(not_applicable),
                    notApplicableInput,
                    options: options.reduce<IChapterLikertComponentQuestionOptionModel[]>(
                        (
                            optAcc: IChapterLikertComponentQuestionOptionModel[],
                            {
                                id: optId,
                                response: optResponse,
                                allow_free_response,
                                free_response
                            }: IChapterLikertComponentQuestionOptionDto
                        ): IChapterLikertComponentQuestionOptionModel[] => {
                            optAcc.push({
                                id: optId,
                                isFreeResponseAllowed: Boolean(allow_free_response),
                                freeResponsePrompt: free_response,
                                response: optResponse
                            })

                            return optAcc
                        },
                        []
                    )
                }

                acc.push(questionModel)

                return acc
            },
            []
        )
    }
}

function _getTrackPracticeComponentModel(
    model: IChapterComponentModel,
    _dto: IChapterTrackPracticeComponentDto
): IChapterTrackPracticeComponentModel {
    return { ...model }
}

function _getBaseChapterComponentModel({
    uuid,
    id,
    order,
    type: dType,
    published: isPublished,
    required: isRequired
}: IChapterComponentDto): IChapterComponentModel {
    return { uuid, id, order, isPublished, isRequired, type: _getChapterComponentEnumFromType(dType) }
}

function _getChapterComponentModels(components: IChapterComponentDto[]): IChapterComponentModel[] {
    return components.map((dto: IChapterComponentDto): IChapterComponentModel => {
        if (isEmpty(dto)) return null

        const baseModel: IChapterComponentModel = _getBaseChapterComponentModel(dto)

        switch (baseModel.type) {
            case EChapterComponentType.Content:
                return _getContentComponentModel(baseModel, dto as IChapterContentComponentDto)
            case EChapterComponentType.QuestionGroup:
                return _getLikertComponentModel(baseModel, dto as IChapterLikertComponentDto)
            case EChapterComponentType.VideoVimeo:
                return _getVideoComponentModel(baseModel, dto as IChapterVideoComponentDto)
            case EChapterComponentType.ConversationScenarios:
                return _getConversationScenariosComponentModel(
                    baseModel,
                    dto as IChapterConversationScenariosComponentDto
                )
            case EChapterComponentType.CoachingMoment:
                return _getCoachingMomentComponentModel(baseModel, dto as IChapterCoachingMomentComponentDto)
            case EChapterComponentType.TrackPractice:
                return _getTrackPracticeComponentModel(baseModel, dto as IChapterTrackPracticeComponentDto)
            case null:
            default:
                return null
        }
    })
}

function _getChapterStatusEnumFromType(status: TChapterStatus): EChapterStatus {
    switch (status) {
        case "in_progress":
            return EChapterStatus.InProgress
        case "complete":
            return EChapterStatus.Completed
        case null:
        default:
            return null
    }
}

function adapter(dto: IChapterDto): IChapterModel {
    return isEmpty(dto)
        ? null
        : isEqual(dto?.redirect_chapter_progress_id, null) || isNumber(dto?.redirect_chapter_progress_id)
          ? ({
                redirectChapterId: dto.redirect_chapter_progress_id
            } as IChapterModel)
          : {
                id: dto.id,
                uuid: dto.uuid,
                phaseOrder: dto.phase_order || 1,
                enrollmentId: dto.enrollment_id,
                phaseProgressId: dto.phase_progress_id,
                moduleTitle: dto.module_title,
                title: dto.title,
                status: _getChapterStatusEnumFromType(dto.status),
                previousChapterId: dto.previous_chapter_progress_id,
                nextChapterId: dto.next_chapter_progress_id,
                components: isEmpty(dto.components)
                    ? []
                    : _getChapterComponentModels(
                          dto.components.filter((c: IChapterComponentDto): boolean => c.published)
                      )
            }
}

export { adapter as chapterByIdQueryAdapter }
