import { useCallback } from "react"

import { type UseQueryOptions, type UseQueryResult, useQuery } from "@tanstack/react-query"
import type { AxiosError } from "axios"

import { ServerStateKeys as EQueryKey } from "@/constants"
import type { IChapterDto as IDto } from "@/pages/participant-chapter/api/dto"
import type { IChapterModel as IModel } from "@/pages/participant-chapter/models"

import { chapterByIdQueryAdapter as adapter } from "./chapter-by-id-query.adapter"
import { chapterByIdQueryFetcher as fetcher } from "./chapter-by-id-query.fetcher"

type TParams = UseQueryOptions & { id: string }
type TQuery = UseQueryResult<IModel, AxiosError>
type TReturn = ReturnType<(params: TParams) => TQuery>

function useHook(params: TParams): TReturn {
    return useQuery<IDto, AxiosError, IModel, [EQueryKey, string]>(
        [EQueryKey.ChapterById, params.id],
        useCallback(fetcher, []),
        {
            // @ts-expect-error no issue here, react-query types mess
            select: useCallback(adapter, []),
            keepPreviousData: true,
            ...params
        }
    )
}

export { useHook as useChapterByIdQuery, type TQuery as TChapterByIdQuery }
