import { type FC, Fragment, type ReactElement } from "react"

import { useParticipantCoachingMomentChatContext as useChatContext } from "../context"
import {
    EParticipantCoachingMomentChatMessageAuthor as EMessageAuthor,
    type IParticipantCoachingMomentChatMessageModel as IChatMessageModel
} from "../types"

import { ParticipantCoachingMomentChatMessage as Message } from "./participant-coaching-moment-chat-message.component"

const Messages: FC = (): ReactElement => {
    const {
        chat: { messages }
    }: ReturnType<typeof useChatContext> = useChatContext()

    return (
        <Fragment>
            {messages.map(
                (m: IChatMessageModel): ReactElement => (
                    <Message key={`pccmc-cm-${m.id}`} message={m} isNotInteractive={m.author === EMessageAuthor.User} />
                )
            )}
        </Fragment>
    )
}

Messages.displayName = "ParticipantCoachingMomentChatMessages"

export { Messages as ParticipantCoachingMomentChatMessages }
