import { type TTFunction, type TTranslationKeys } from "$/i18n/i18n"

import { type ISelectOption } from "@/3514/components"

import {
    EParticipantCoachingMomentChatFeedbackSeverity as EFeedbackSeverity,
    EParticipantCoachingMomentChatFeedbackType as EFeedbackType,
    ECoachingMomentChatModalFeedbackVariant as EFeedbackVariant,
    ECoachingMomentChatModalFeedbackVariant as EVariant
} from "../types"

const SUMMARY_GENERATION_DURATION_IN_MS: number = 3000

const chatModalTitlesMap: { [V in EFeedbackVariant]: keyof TTranslationKeys } = {
    [EFeedbackVariant.General]: "participantSide.chapter.coachingMomentChat.feedbackModal.beta.title",
    [EFeedbackVariant.Message]: "participantSide.chapter.coachingMomentChat.feedbackModal.message.title",
    [EFeedbackVariant.ApiError]: "participantSide.chapter.coachingMomentChat.feedbackModal.apiError.title"
}

const chatFeedbackVariantContextMap: { [V in EVariant]: EFeedbackType } = {
    [EVariant.General]: EFeedbackType.General,
    [EVariant.Message]: EFeedbackType.Message,
    [EVariant.ApiError]: EFeedbackType.Error
}

function getChatFeedbackSeverityOptions(t: TTFunction): ISelectOption[] {
    return [
        {
            value: EFeedbackSeverity.Mild,
            id: EFeedbackSeverity.Mild,
            label: t("participantSide.chapter.coachingMomentChat.errorsSeverity.mild")
        },
        {
            value: EFeedbackSeverity.Medium,
            id: EFeedbackSeverity.Medium,
            label: t("participantSide.chapter.coachingMomentChat.errorsSeverity.medium")
        },
        {
            value: EFeedbackSeverity.Severe,
            id: EFeedbackSeverity.Severe,
            label: t("participantSide.chapter.coachingMomentChat.errorsSeverity.severe")
        }
    ]
}

const config = {
    SUMMARY_GENERATION_DURATION_IN_MS,
    chatModalTitlesMap,
    getChatFeedbackSeverityOptions,
    chatFeedbackVariantContextMap
}

export { config as participantCoachingMomentChatUiConfig }
