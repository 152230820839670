import {
    type ComponentProps,
    type Dispatch,
    type FC,
    type ReactElement,
    type ReactNode,
    type SetStateAction,
    lazy,
    useState
} from "react"

import classNames from "classnames"

import { useCSSInsertion } from "@/hooks"
import { emptyCallback } from "@/shared/types/functions"

import { videoRendererConfig as config } from "./video-renderer.config"
import { EVideoRendererSourceProvider as EProvider } from "./video-renderer.types"
import { videoRendererUtils as utils } from "./video-renderer.utils"

import { ESpinnerSize, LoadableComponent, Spinner } from ".."

const Player = lazy((): Promise<typeof import("react-player/lazy")> => import("react-player/lazy"))

type TProps = Omit<ComponentProps<typeof Player>, "url"> & {
    url: string
    provider?: EProvider
}

const classes: {
    container(isPlayerLoading: boolean, isPlayerCrashed: boolean): string
    playerWrapper(isPlayerLoading: boolean): string
    spinnerWrapper(): string
} = {
    container: (isPlayerLoading: boolean, isPlayerCrashed: boolean): string =>
        classNames("relative w-full h-full", isPlayerLoading && "min-h-[100px]", isPlayerCrashed && "hidden"),
    playerWrapper: (isPlayerLoading: boolean): string =>
        classNames("video-renderer w-full h-full", isPlayerLoading && "hidden"),
    spinnerWrapper: (): string => "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
}

const Component: FC<TProps> = ({
    controls = true,
    playsinline = true,
    width = "100%",
    height = "100%",
    url,
    onReady = emptyCallback,
    onError = emptyCallback,
    provider = EProvider.Vimeo,
    ...rest
}: TProps): ReactElement => {
    const [isPlayerLoading, setIsPlayerLoading]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true)

    const [isPlayerCrashed, setIsPlayerCrashed]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false)

    useCSSInsertion({
        cssString: config.cssInsertionStyles
    })

    const PlayerWrapper: FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }): ReactElement => (
        <div className={classes.playerWrapper(isPlayerLoading)}>{children}</div>
    )

    return (
        !isPlayerCrashed && (
            <div className={classes.container(isPlayerLoading, isPlayerCrashed)}>
                {isPlayerLoading && <Spinner size={ESpinnerSize.Large} wrapperClassName={classes.spinnerWrapper()} />}

                <LoadableComponent
                    component={
                        <Player
                            url={utils.getVideoLinkByProvider(url, provider)}
                            controls={controls}
                            playsinline={playsinline}
                            width={width}
                            height={height}
                            wrapper={PlayerWrapper}
                            onReady={(): void => (setIsPlayerLoading(false), onReady?.())}
                            onError={(e: unknown): void => (console.log(e), setIsPlayerCrashed(true), onError?.())}
                            {...rest}
                        />
                    }
                />
            </div>
        )
    )
}

Component.displayName = "VideoRenderer"

export { Component as VideoRenderer }
