import {
    type BaseSyntheticEvent,
    type Dispatch,
    type FC,
    type KeyboardEvent,
    type MouseEvent,
    type ReactElement,
    type SetStateAction,
    isValidElement,
    useState
} from "react"

import classNames from "classnames"

import { isString } from "$/utils/gates"

import { Typography } from "@/3514/components"
import { getTransitionTwClasses } from "@/3514/utils"
import { type IAuthContext, useAuthContext } from "@/context"
import Avatar from "@/shared/avatar/Avatar"

import { ParticipantCoachingMomentChatFeedbackIcon as FeedbackIcon } from "../assets"
import { useParticipantCoachingMomentChatContext as useChatContext } from "../context"
import {
    EParticipantCoachingMomentChatMessageAuthor as EMessageAuthor,
    ECoachingMomentChatModalFeedbackVariant as EModalVariant,
    type IParticipantCoachingMomentChatMessageModel as IMessageModel,
    type TParticipantCoachingMomentChatMessageUiModel as TMessageModel
} from "../types"

import { participantCoachingMomentOwlComponents as owls } from "./participant-coaching-moment-chat-owls-components"

type TProps = {
    message: TMessageModel
    isNotInteractive?: boolean
    isCentered?: boolean
    withMessageWithNoPaddings?: boolean
}

const classes: {
    container(
        author: EMessageAuthor,
        isNotInteractive: boolean,
        isMessageCentered: boolean,
        withNoPadding: boolean
    ): string
    messageRow(author: EMessageAuthor, isMessageCentered: boolean): string
    messageWrapper(): string
    message(author: EMessageAuthor, isContentAString: boolean): string
    messageFeedbackContainer(): string
} = {
    container: (
        author: EMessageAuthor,
        isNotInteractive: boolean,
        isMessageCentered: boolean,
        withNoPaddings: boolean
    ): string =>
        classNames(
            "flex w-full bg-transparent py-[7px] relative",
            getTransitionTwClasses("colors"),
            !isNotInteractive && "hover:!bg-black/5 focus:!bg-black/5",
            author === EMessageAuthor.User
                ? [
                      withNoPaddings ? "p-[0px]" : "pl-[55px] md:pl-[40px] pr-[20px]",
                      isMessageCentered ? "justify-center" : "justify-end"
                  ]
                : [withNoPaddings ? "p-[0px]" : "pl-[20px] pr-[55px] md:pr-[40px]", !isNotInteractive && "group"]
        ),
    messageRow: (author: EMessageAuthor, isMessageCentered: boolean): string =>
        classNames(
            "flex gap-x-[5px] items-end",
            author === EMessageAuthor.User
                ? [isMessageCentered || "ml-auto"]
                : [isMessageCentered ? "mr-[0px]" : "mr-auto", "flex-row-reverse"]
        ),
    messageWrapper: (): string => "flex items-center gap-x-[5px] relative",
    message: (author: EMessageAuthor, isContentAString: boolean): string =>
        classNames(
            "rounded-[20px] w-auto selection:bg-blue-300",
            getTransitionTwClasses(),
            isContentAString ? "py-[9px] px-[20px] break-words" : "p-[10px]",
            author === EMessageAuthor.User ? "bg-blue-500 max-w-[385px]" : "bg-red-500 max-w-[445px]"
        ),
    messageFeedbackContainer: (): string =>
        classNames(
            [
                "w-[25px]",
                "h-[25px]",
                "items-center",
                "justify-center",
                "cursor-pointer",
                "p-[5px]",
                "bg-white",
                "mx-[5px]",
                "md:mx-[15px]",
                "rounded-[5px]",
                "absolute",
                "left-[calc(100%+5px)]",
                "md:relative",
                "md:left-[0px]",
                "border-[1px]",
                "border-gray-400",
                "hidden",
                "hover:border-gray-300",
                "focus:border-gray-300",
                "group-hover:flex",
                "group-focus:flex"
            ],
            getTransitionTwClasses()
        )
}

const avatarDimension: number = 30

const Message: FC<TProps> = ({
    message,
    isNotInteractive = false,
    isCentered = false,
    withMessageWithNoPaddings = false
}: TProps): ReactElement => {
    const { message: messageText, author }: TMessageModel = message

    const { user }: IAuthContext = useAuthContext()

    const [isIconVisible, setIsIconVisible]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false)

    const {
        modal: { handleModalOpen }
    }: ReturnType<typeof useChatContext> = useChatContext()

    const handleFeedbackAction: (e: BaseSyntheticEvent) => void = (e: KeyboardEvent & MouseEvent): void =>
        (e.key === "Enter" || e.type === "click") && handleModalOpen(EModalVariant.Message, message as IMessageModel)

    return (
        <div className={classes.container(author, isNotInteractive, isCentered, withMessageWithNoPaddings)}>
            <div className={classes.messageRow(author, isNotInteractive)}>
                <div className={classes.messageWrapper()}>
                    <div className={classes.message(author, !isValidElement(messageText))}>
                        {isString(messageText) ? (
                            <Typography text={messageText} />
                        ) : (
                            isValidElement(messageText) && messageText
                        )}
                    </div>
                    {!isNotInteractive && author === EMessageAuthor.AI && (
                        <div
                            role="button"
                            tabIndex={0}
                            className={classes.messageFeedbackContainer()}
                            onClick={handleFeedbackAction}
                            onKeyDown={handleFeedbackAction}
                            onMouseEnter={(): void => setIsIconVisible(true)}
                            onMouseLeave={(): void => setIsIconVisible(false)}
                        >
                            <FeedbackIcon isActive={isIconVisible} />
                        </div>
                    )}
                </div>
                {author === EMessageAuthor.User ? (
                    <Avatar
                        width={avatarDimension}
                        height={avatarDimension}
                        url={user?.photo}
                        alt={`${user?.firstName} avatar`}
                        imgClassName={`min-w-[${avatarDimension}px]`}
                    />
                ) : (
                    <owls.DefaultLogo />
                )}
            </div>
        </div>
    )
}

Message.displayName = "ParticipantCoachingChatMessage"

export { Message as ParticipantCoachingMomentChatMessage }
