import {
    type ChangeEvent,
    type FC,
    type KeyboardEvent,
    type MutableRefObject,
    type ReactElement,
    useCallback,
    useMemo,
    useRef
} from "react"

import classNames from "classnames"
import { type UseFormReturn, useForm } from "react-hook-form"
import { CSSTransition } from "react-transition-group"

import { isEmpty } from "$/utils/gates"

import {
    Button,
    EButtonSize,
    EButtonVariant,
    ETextAreaScrollbarVariant,
    ETypographyColor,
    ETypographySize,
    ETypographyTag,
    TextArea,
    Typography
} from "@/3514/components"
import { getCssIdentifierFromFileName, getScaleAnimationCssString } from "@/3514/utils"
import { type IUseTranslation, useCSSInsertion, useTranslation } from "@/hooks"
import type { TEmptyAsyncCallback } from "@/shared/types/functions"

import { ParticipantCoachingMomentChatOwlLetterIcon as LetterIcon } from "../assets"
import { useParticipantCoachingMomentChatContext as useChatContext } from "../context"
import {
    EParticipantCoachingMomentChatStatus as EChatStatus,
    EParticipantCoachingMomentChatInputFormField as EFormField,
    EParticipantCoachingMomentChatMessageAuthor as EMessageAuthor,
    type TParticipantCoachingMomentChatInputForm as TForm
} from "../types"
import { participantCoachingMomentChatInputFormResolver as formResolver } from "../validation"

const classes: {
    field(): string
    container(hasError: boolean): string
    infoBox(): string
    input(): string
    hint(): string
    infoBoxIconWrapper(): string
    icon(): string
} = {
    field: (): string => "bg-white rounded-[10px] mt-[5px] mx-[10px] mb-[10px] h-auto",
    container: (hasError: boolean): string =>
        classNames(
            [
                "rounded-[8px]",
                "flex",
                "flex-col",
                "gap-y-[5px]",
                "pt-[12px]",
                "pr-[8px]",
                "pb-[8px]",
                "pl-[15px]",
                "border-[1px]",
                "border-blue-400",
                "!border-t-transparent",
                "focus:border-blue-600",
                "hover:border-blue-600",
                "active:border-blue-600",
                "h-auto"
            ],
            hasError && "cursor-not-allowed"
        ),
    infoBox: (): string => "flex ml-auto items-end gap-x-[5px] h-[30px]",
    input: (): string => "caret-gray-300 selection:bg-blue-300",
    hint: (): string => "pb-[2px] select-none",
    infoBoxIconWrapper: (): string => "border-[1px] border-transparent focus:border-accent pl-[7px]",
    icon: (): string => "cursor-pointer"
}

const displayName: string = "ParticipantCoachingMomentChatInput"

const animationClassNameId: string = getCssIdentifierFromFileName(displayName)

const Component: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const {
        chat: {
            hasError,
            conversationId,
            status,
            messages,
            createMessageMutation: { mutateAsync, isLoading: isMessageSending }
        }
    }: ReturnType<typeof useChatContext> = useChatContext()

    const {
        setValue,
        reset: resetForm,
        handleSubmit,
        formState: { isValid: isFormValid }
    }: UseFormReturn<TForm> = useForm<TForm>({
        resolver: formResolver,
        reValidateMode: "onChange",
        criteriaMode: "all",
        defaultValues: { [EFormField.ChatInput]: String() }
    })

    const isChatInProgress: boolean = [
        EChatStatus.InProgress,
        EChatStatus.Ready,
        EChatStatus.Paused,
        EChatStatus.GeneratingSummary
    ].includes(status)

    const formRef: MutableRefObject<HTMLFormElement> = useRef<HTMLFormElement>(null)

    const inputRef: MutableRefObject<HTMLTextAreaElement> = useRef<HTMLTextAreaElement>(null)

    const handleMessageSend: (values: TForm) => Promise<void> = useCallback(
        async (values: TForm): Promise<void> =>
            !hasError &&
            (await mutateAsync(
                { conversationId, message: values[EFormField.ChatInput] },
                {
                    onSettled: async (): Promise<void> => (
                        resetForm({ [EFormField.ChatInput]: String() }),
                        setValue(EFormField.ChatInput, String()),
                        (inputRef.current.value = String()),
                        void 0
                    )
                }
            ),
            void 0),
        [conversationId, hasError, mutateAsync, resetForm, setValue]
    )

    const handleFormSubmit: TEmptyAsyncCallback = useMemo(
        (): TEmptyAsyncCallback => handleSubmit(handleMessageSend),
        [handleMessageSend, handleSubmit]
    )

    const handleInputChange: (e: ChangeEvent<HTMLTextAreaElement>) => void = useCallback(
        (e: ChangeEvent<HTMLTextAreaElement>): void => (
            setValue(EFormField.ChatInput, e.target.value, {
                shouldTouch: true,
                shouldValidate: true
            }),
            (inputRef.current.value = e.target.value),
            void 0
        ),
        [setValue]
    )

    const handleInputKeyDown: (e: KeyboardEvent) => void = useCallback(
        (event: KeyboardEvent): Promise<void> => {
            if (event.key === "Enter") {
                event.preventDefault()
                if (!event.shiftKey) {
                    handleFormSubmit()
                } else {
                    setValue(EFormField.ChatInput, `${inputRef.current.value}\n`, {
                        shouldValidate: true,
                        shouldTouch: true
                    })
                    inputRef.current.value = `${inputRef.current.value} \n`
                    inputRef.current.scrollTop = inputRef.current.scrollHeight
                }
            }
            return void 0
        },
        [handleFormSubmit, setValue]
    )

    const shouldInputBeLocked: boolean =
        (status !== EChatStatus.Completed &&
            (messages[messages.length - 1]?.author === EMessageAuthor.User || isEmpty(messages))) ||
        status === EChatStatus.GeneratingSummary

    const isTextareaDisabled: boolean = shouldInputBeLocked || hasError

    const isButtonDisabled: boolean = !isFormValid || shouldInputBeLocked || hasError

    useCSSInsertion({
        cssString: getScaleAnimationCssString({
            identifier: animationClassNameId,
            durationInMs: 150,
            scaleTo: 0.85
        })
    })

    return (
        <CSSTransition
            timeout={250}
            classNames={animationClassNameId}
            in={isChatInProgress}
            nodeRef={formRef}
            mountOnEnter
            unmountOnExit
        >
            <form tabIndex={-1} ref={formRef} onSubmit={handleFormSubmit} className={classes.field()}>
                <div role="tabpanel" className={classes.container(hasError)}>
                    <TextArea
                        ref={inputRef}
                        name={EFormField.ChatInput}
                        textColor={ETypographyColor.Black}
                        textSize={ETypographySize.Medium}
                        onKeyDown={handleInputKeyDown}
                        onChange={handleInputChange}
                        className={classes.input()}
                        isDisabled={isTextareaDisabled}
                        borderRadius={{ tl: 0, tr: 0, br: 0, bl: 0 }}
                        scrollbarVariant={ETextAreaScrollbarVariant.Thin}
                        withDynamicHeight
                        withNoResize
                        withNoPaddings
                        withItalicPlaceholder
                        withNoBorder
                        withTransparentBorder
                        placeholder={t("common.inputPlaceholder")}
                    />
                    <div className={classes.infoBox()}>
                        <Typography
                            text={t("participantSide.chapter.coachingMoment.chat.hint")}
                            color={ETypographyColor.DarkGray}
                            tag={ETypographyTag.Span}
                            size={ETypographySize.ExtraSmall}
                            className={classes.hint()}
                            isItalic
                        />
                        <Button
                            tabIndex={0}
                            variant={EButtonVariant.EmptyTransparent}
                            size={EButtonSize.Container}
                            isDisabled={isButtonDisabled}
                            isBusy={isMessageSending}
                            className={classes.infoBoxIconWrapper()}
                        >
                            <LetterIcon isAccent={isFormValid} className={classes.icon()} />
                        </Button>
                    </div>
                </div>
            </form>
        </CSSTransition>
    )
}

Component.displayName = displayName

export { Component as ParticipantCoachingMomentChatInput }
