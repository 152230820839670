import { http } from "$/http"

import { EParticipantCoachingMomentChatApiMutationUrl as EMutationUrl } from "../../../config"

import type {
    TParticipantCoachingMomentChatCreateMessageMutationParams as TParams,
    TParticipantCoachingMomentChatCreateMessageMutationResponse as TResponse
} from "./participant-coaching-moment-chat.create-chat-message.hook"

async function fetcher({ message, conversationId }: TParams): Promise<TResponse> {
    return http.post(EMutationUrl.CreateMessage(Number(conversationId)), { message })
}

export { fetcher as participantCoachingMomentChatCreateMessageFetcher }
