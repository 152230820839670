import { type FC, type LazyExoticComponent, lazy } from "react"

const ParticipantCoachingMomentChatFeedbackModal: LazyExoticComponent<FC> = lazy(
    (): Promise<{ default: FC }> =>
        import("./participant-coaching-moment-chat-feedback-modal-container.component").then(
            ({ ParticipantCoachingMomentChatFeedbackModalContainer: C }): { default: FC } => ({
                default: C
            })
        )
)

export { ParticipantCoachingMomentChatFeedbackModal }
