import { http } from "$/http"

import { EParticipantCoachingMomentChatApiMutationUrl as EMutationUrl } from "../../../config"

import type {
    TParticipantCoachingMomentChatCreateFeedbackMutationParams as TParams,
    TParticipantCoachingMomentChatCreateFeedbackMutationResponse as TResponse
} from "./participant-coaching-moment-chat.create-feedback.hook"

async function fetcher(params: TParams): Promise<TResponse> {
    return http.post(EMutationUrl.CreateFeedback(), params)
}

export { fetcher as participantCoachingMomentChatCreateFeedbackFetcher }
