import { isEmpty } from "$/utils/gates"

import {
    EParticipantCoachingMomentChatStatus as EChatStatus,
    type IParticipantCoachingMomentChatMessageModel as IConversationMessageModel,
    type IParticipantCoachingMomentChatConversationModel as IConversationModel,
    type IParticipantCoachingMomentChatNewMessagesErrorModel as IErrorModel,
    type IParticipantCoachingMomentChatNewMessagesModel as INewMessagesModel,
    type TParticipantCoachingMomentSeedQuestionFormField as TQuestionFormField
} from "../types"
import { participantCoachingMomentChatUiUtils as uiUtils } from "../utils"

const { mergeChatMessages: mergeMessages, getChatStatusEnumFromConversationStatusEnum: getChatStatus } = uiUtils

type TState = {
    form: { questions: TQuestionFormField[]; isSubmitted: boolean }
    chat: {
        isChatLoading: boolean
        conversationId: number | null
        messages: IConversationMessageModel[]
        status: EChatStatus
        summary: string | null
        hasError: boolean
        error: IErrorModel | null
    }
}

enum EActionType {
    LOAD_QUESTIONS_ANSWERS = "LOAD_QUESTIONS_ANSWERS",
    SUBMIT_QUESTION = "SUBMIT_QUESTION",
    SET_CONVERSATION_ID = "SET_CONVERSATION_ID",
    LOAD_CONVERSATION = "LOAD_CONVERSATION",
    ADD_MESSAGES = "ADD_MESSAGES",
    SEND_MESSAGE = "SEND_MESSAGE",
    REMOVE_MESSAGE = "REMOVE_MESSAGE",
    CHANGE_STATUS = "CHANGE_STATUs",
    TRIGGER_ERROR = "TRIGGER_ERROR"
}

type TAction =
    | { type: EActionType.LOAD_QUESTIONS_ANSWERS; payload: TQuestionFormField[] }
    | { type: EActionType.SUBMIT_QUESTION; payload: Pick<TQuestionFormField, "id" | "answer"> }
    | { type: EActionType.SET_CONVERSATION_ID; payload: number }
    | { type: EActionType.LOAD_CONVERSATION; payload: { conversation: IConversationModel; status?: EChatStatus } }
    | { type: EActionType.ADD_MESSAGES; payload: INewMessagesModel }
    | {
          type: EActionType.SEND_MESSAGE
          payload: { message?: IConversationMessageModel; optimisticMessageId?: number; responseMessageId?: number }
      }
    | { type: EActionType.REMOVE_MESSAGE; payload: number }
    | { type: EActionType.CHANGE_STATUS; payload: EChatStatus }
    | { type: EActionType.TRIGGER_ERROR; payload?: boolean }

const initialState: TState = {
    form: { questions: [], isSubmitted: false },
    chat: {
        isChatLoading: true,
        conversationId: null,
        messages: [],
        status: EChatStatus.Locked,
        summary: String(),
        hasError: false,
        error: null
    }
}

function reducer(state: TState, { type: actionType, payload }: TAction): TState {
    switch (actionType) {
        // form
        case EActionType.LOAD_QUESTIONS_ANSWERS: {
            return {
                ...state,
                form: {
                    isSubmitted: true,
                    questions: payload
                }
            }
        }
        case EActionType.SUBMIT_QUESTION: {
            return {
                ...state,
                form: {
                    ...state.form,
                    questions: state.form.questions.map(
                        (field: TQuestionFormField): TQuestionFormField =>
                            field.id === payload.id ? { ...field, answer: payload.answer } : field
                    )
                }
            }
        }
        // chat
        case EActionType.SET_CONVERSATION_ID:
            return {
                ...state,
                chat: { ...state.chat, conversationId: payload }
            }
        case EActionType.LOAD_CONVERSATION:
            return {
                ...state,
                chat: {
                    isChatLoading: false,
                    status: payload.status || getChatStatus(payload.conversation.status),
                    conversationId: payload.conversation.id,
                    messages: payload.conversation.messages,
                    summary: payload.conversation.summary,
                    hasError: false,
                    error: undefined
                }
            }
        case EActionType.ADD_MESSAGES:
            // eslint-disable-next-line
            const tempState: TState = { ...state }

            if (!isEmpty(payload.errors)) {
                // eslint-disable-next-line
                tempState.chat.error = payload.errors[0]
                tempState.chat.status = EChatStatus.Paused
                tempState.chat.hasError = true
            } else {
                tempState.chat.messages = isEmpty(payload.messages)
                    ? state.chat.messages
                    : mergeMessages(state.chat.messages, payload.messages)
                payload.messages.forEach((msg: IConversationMessageModel): void => {
                    if (!isEmpty(msg?.conversation?.summary)) {
                        tempState.chat.summary = msg.conversation.summary
                        tempState.chat.status = EChatStatus.GeneratingSummary
                    } else {
                        tempState.chat.status = EChatStatus.InProgress
                    }
                })
            }

            return tempState
        case EActionType.SEND_MESSAGE:
            if (payload.optimisticMessageId && payload.responseMessageId) {
                return {
                    ...state,
                    chat: {
                        ...state.chat,
                        status: EChatStatus.InProgress,
                        messages: state.chat.messages.map(
                            (m: IConversationMessageModel): IConversationMessageModel =>
                                m.id === payload.optimisticMessageId ? { ...m, id: payload.responseMessageId } : m
                        )
                    }
                }
            }
            return {
                ...state,
                chat: {
                    ...state.chat,
                    status: EChatStatus.InProgress,
                    messages: [...state.chat.messages, payload.message]
                }
            }
        case EActionType.REMOVE_MESSAGE:
            return {
                ...state,
                chat: {
                    ...state.chat,
                    messages: state.chat.messages.filter((m: IConversationMessageModel): boolean => m.id !== payload)
                }
            }
        case EActionType.TRIGGER_ERROR:
            return {
                ...state,
                chat: { ...state.chat, hasError: true, status: EChatStatus.Paused }
            }
        case EActionType.CHANGE_STATUS: {
            return { ...state, chat: { ...state.chat, status: payload } }
        }
        default:
            return state
    }
}

export {
    reducer as participantCoachingMomentChatReducer,
    initialState as participantCoachingMomentChatInitialState,
    EActionType as EParticipantCoachingMomentChatActionType,
    type TState as TParticipantCoachingMomentChatState,
    type TAction as TParticipantCoachingMomentChatAction
}
