import {
    EParticipantCoachingMomentChatStatus as EChatStatus,
    EParticipantCoachingMomentChatConversationStatus as EConversationStatus,
    type TParticipantCoachingMomentSeedQuestionFormField as IChatFormField,
    type IParticipantCoachingMomentChatMessageModel as IConversationMessageModel,
    type TParticipantCoachingMomentChatSeedQuestion as TChatSeedQuestion
} from "../types"

function getChatSummaryClassNamesStringForInsertion(elementCn: string): string {
    return `
        .${elementCn} { text-align: initial; } 
        .${elementCn} span, 
        .${elementCn} p, 
        .${elementCn} strong, 
        .${elementCn}.b { font-size: 14px !important; line-height: 21px !important; color: #333333 !important; }
        .${elementCn} b, 
        .${elementCn} strong { font-weight: 800 !important; }
        .${elementCn} p { margin: 0 !important; }
        .${elementCn} p:not(:last-of-type) { margin-bottom: 15px !important; }
    `
}

function getChatTypingIndicatorClassNamesStringForInsertion(): string {
    return `     
      @keyframes bounce {
        0%, 60%, 100% {
          transform: translateY(0);
        }
        30% {
          transform: translateY(-4px);
        }
      }
      .animate-bounce-dot {
        animation: bounce 1.3s linear infinite;
      }
      .animate-delay-150 {
        animation-delay: 0.15s;
      }
      .animate-delay-300 {
        animation-delay: 0.3s;
      }
`
}

function mergeChatMessages(
    prev: IConversationMessageModel[],
    incoming: IConversationMessageModel[]
): IConversationMessageModel[] {
    const messageMap = new Map<number, IConversationMessageModel>()

    for (const msg of prev) {
        if (msg?.id) {
            messageMap.set(msg.id, msg)
        }
    }

    for (const msg of incoming) {
        if (msg?.id) {
            messageMap.set(msg.id, msg)
        }
    }

    return Array.from(messageMap.values()).sort(
        (a: IConversationMessageModel, b: IConversationMessageModel): number => a.id - b.id
    )
}

function getChatStatusEnumFromConversationStatusEnum(status: EConversationStatus): EChatStatus {
    switch (status) {
        case EConversationStatus.Completed:
            return EChatStatus.Completed
        case EConversationStatus.InProgress:
        default:
            return EChatStatus.Ready
    }
}

function mapSeedQuestionsToFormFields(questions: TChatSeedQuestion[]): IChatFormField[] {
    return questions.map(
        ({ question, questionUuid, answer }: TChatSeedQuestion, index: number): IChatFormField => ({
            index,
            id: questionUuid,
            answer: answer! ?? String(),
            question: question! ?? String()
        })
    )
}

const utils = {
    getChatSummaryClassNamesStringForInsertion,
    getChatTypingIndicatorClassNamesStringForInsertion,
    mergeChatMessages,
    mapSeedQuestionsToFormFields,
    getChatStatusEnumFromConversationStatusEnum
}

export { utils as participantCoachingMomentChatUiUtils }
