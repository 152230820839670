import classNames from "classnames"

import type { TTranslationKeys } from "$/i18n/i18n"

import { getTransitionTwClasses } from "@/3514/utils"

import {
    EVerticalProgressBarColorStatus,
    EVerticalProgressBarFillLevel,
    EVerticalProgressBarIndicatorVariant
} from "./vertical-progress-bar.types"

const VERTICAL_PROGRESS_BAR_STAR_FILLED_COLOR: string = "#FFF500"

const indicatorStatusesMap: { [K in EVerticalProgressBarIndicatorVariant]: string } = {
    [EVerticalProgressBarIndicatorVariant.Open]: "bg-green-100",
    [EVerticalProgressBarIndicatorVariant.Active]: "bg-blue-200 outline-offset-[5px] outline-[5px] outline-blue-200",
    [EVerticalProgressBarIndicatorVariant.Overdue]: "bg-red-200",
    [EVerticalProgressBarIndicatorVariant.Inactive]: "bg-gray-400"
}

const barFillColorMap: {
    [S in EVerticalProgressBarColorStatus]: string
} = {
    [EVerticalProgressBarColorStatus.NotStarted]: "bg-gray-400",
    [EVerticalProgressBarColorStatus.Expired]: classNames("bg-red-200", getTransitionTwClasses("colors")), // hover tbu per dedicated task
    [EVerticalProgressBarColorStatus.InTime]: classNames("bg-green-200", getTransitionTwClasses("colors")) // // hover tbu per dedicated task
}

const defaultTooltipConfig: { [L in EVerticalProgressBarFillLevel]: keyof TTranslationKeys } = {
    [EVerticalProgressBarFillLevel.NotFilled]: "participantSide.dashboard.module.tooltip.notStarted",
    [EVerticalProgressBarFillLevel.OnePieceFilled]: "participantSide.dashboard.module.tooltip.onePieceFilled",
    [EVerticalProgressBarFillLevel.TwoPiecesFilled]: "participantSide.dashboard.module.tooltip.twoPieceFilled",
    [EVerticalProgressBarFillLevel.ThreePiecesFilledWithStar]:
        "participantSide.dashboard.module.tooltip.threePieceFilled"
}

const config = {
    VERTICAL_PROGRESS_BAR_STAR_FILLED_COLOR,
    indicatorStatusesMap,
    barFillColorMap,
    defaultTooltipConfig
}

export { config as verticalProgressBarConfig }
