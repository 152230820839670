import {
    type BaseSyntheticEvent,
    type Dispatch,
    type FC,
    type KeyboardEvent,
    type MouseEvent,
    type MutableRefObject,
    type ReactElement,
    type SetStateAction,
    useRef,
    useState
} from "react"

import classNames from "classnames"
import { CSSTransition } from "react-transition-group"

import {
    Button,
    EButtonSize,
    EButtonVariant,
    ESpecialChar,
    ETypographyAlignment,
    ETypographyColor,
    ETypographyFontWeight,
    ETypographySize,
    ETypographyTag,
    SpecialChars,
    Typography
} from "@/3514/components"
import { getCssIdentifierFromFileName, getScaleAnimationCssString, getTransitionTwClasses } from "@/3514/utils"
import {
    type IUseTranslation,
    type TTranslationKeys,
    type TUseManualHover,
    useCSSInsertion,
    useManualHover,
    useTranslation
} from "@/hooks"
import { CrossIconSvg } from "@/svgs/CrossIconSvg"

import { ParticipantCoachingMomentChatErrorIcon as ErrorIcon } from "../assets"
import { useParticipantCoachingMomentChatContext as useChatContext } from "../context"
import {
    EParticipantCoachingMomentChatErrorBoxVariant as EErrorVariant,
    ECoachingMomentChatModalFeedbackVariant as EModalVariant
} from "../types"

type TProps = {
    variant?: EErrorVariant
    isNotInteractive?: boolean
}

const displayName: string = "ParticipantCoachingMomentChatErrorBox"

const animationClassNameId: string = getCssIdentifierFromFileName(displayName)

const titleTextsMap: { [E in EErrorVariant]: keyof TTranslationKeys } = {
    [EErrorVariant.OffTopic]: "participantSide.chapter.coachingMoment.chat.error.offTopic.title",
    [EErrorVariant.Api]: "participantSide.chapter.coachingMoment.chat.error.api.title"
}

const descriptionTextsMap: { [E in EErrorVariant]: keyof TTranslationKeys } = {
    [EErrorVariant.OffTopic]: "participantSide.chapter.coachingMoment.chat.error.offTopic.description",
    [EErrorVariant.Api]: "participantSide.chapter.coachingMoment.chat.error.api.description"
}

const classes: {
    container(isApiError: boolean): string
    textWrapper(isApiError: boolean): string
    text(): string
    textActionWrapper(): string
    textAction(isActionAffected: boolean): string
    closeBtn(): string
} = {
    container: (isApiError: boolean): string =>
        classNames(
            [
                "flex",
                "gap-x-[15px]",
                "relative",
                "pt-[15px]",
                "pr-[40px]",
                "pl-[20px]",
                "max-w-[580px]",
                "self-center",
                "my-[7px]",
                "mx-auto",
                "w-[calc(100%-20px)]",
                "md:w-full",
                "rounded-[10px]"
            ],
            isApiError ? "bg-gray-500 pb-[15px]" : "bg-red-300 pb-[20px]"
        ),
    textWrapper: (isApiError: boolean): string => classNames(isApiError ? "flex flex-col w-full" : "inline"),
    text: (): string => "inline",
    textActionWrapper: (): string => "mx-auto group",
    textAction: (isActionAffected: boolean): string =>
        classNames(
            "underline underline-accent cursor-pointer",
            isActionAffected && "underline-offset-[5px]",
            getTransitionTwClasses()
        ),
    closeBtn: (): string =>
        classNames(
            [
                "absolute",
                "p-[4px]",
                "top-[5px]",
                "right-[7px]",
                "rounded-full",
                "hover:cursor-pointer",
                "border-[1px]",
                "border-transparent",
                "hover:!border-gray-200",
                "focus:!border-gray-200"
            ],
            getTransitionTwClasses()
        )
}

const Box: FC<TProps> = ({ variant = EErrorVariant.Api, isNotInteractive = false }: TProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    useCSSInsertion({
        cssString: getScaleAnimationCssString({
            identifier: animationClassNameId,
            durationInMs: 150,
            scaleTo: 0.7
        })
    })

    const {
        chat: { hasError },
        modal: { handleModalOpen }
    }: ReturnType<typeof useChatContext> = useChatContext()

    const isApiError: boolean = variant === EErrorVariant.Api

    const [isDiscarded, setIsDiscarded]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false)

    const boxRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

    const { onMouseEnter, onMouseLeave, onBlur, onFocus, isActionAffected }: TUseManualHover = useManualHover()

    const handleHideAction: (e?: BaseSyntheticEvent) => void = (e: KeyboardEvent & MouseEvent): void =>
        (e.key === "Enter" || e.type === "click") && setIsDiscarded(true)

    const handleReportAction: (e?: BaseSyntheticEvent) => void = (e: KeyboardEvent & MouseEvent): void =>
        (e.key === "Enter" || e.type === "click") && handleModalOpen(EModalVariant.ApiError)

    return (
        <CSSTransition
            timeout={250}
            in={hasError && !isDiscarded}
            classNames={animationClassNameId}
            nodeRef={boxRef}
            mountOnEnter={hasError}
            unmountOnExit
        >
            <div ref={boxRef} className={classes.container(isApiError)}>
                {isApiError || <ErrorIcon />}

                <div className={classes.textWrapper(isApiError)}>
                    <Typography
                        className={classes.text()}
                        alignment={isApiError ? ETypographyAlignment.Center : ETypographyAlignment.Left}
                        color={isApiError ? ETypographyColor.Error : ETypographyColor.Black}
                        size={ETypographySize.Small}
                        tag={ETypographyTag.Paragraph}
                        text={t(titleTextsMap[variant])}
                        weight={ETypographyFontWeight.Bold}
                    />

                    {isApiError || SpecialChars[ESpecialChar.EmptySpace]}

                    <Typography
                        className={classes.text()}
                        alignment={isApiError ? ETypographyAlignment.Center : ETypographyAlignment.Left}
                        size={ETypographySize.Small}
                        tag={ETypographyTag.Span}
                        text={t(descriptionTextsMap[variant])}
                    />

                    {isApiError || SpecialChars[ESpecialChar.EmptySpace]}

                    {isNotInteractive || (
                        <div
                            role="link"
                            tabIndex={0}
                            className={classNames(classes.text(), classes.textActionWrapper())}
                            onClick={handleReportAction}
                            onKeyDown={handleReportAction}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                        >
                            <Typography
                                className={classNames(classes.text(), classes.textAction(isActionAffected))}
                                size={ETypographySize.Small}
                                weight={isActionAffected ? ETypographyFontWeight.Bold : ETypographyFontWeight.Default}
                                tag={ETypographyTag.Span}
                                text={t("participantSide.chapter.coachingMoment.chat.error.action")}
                                color={ETypographyColor.Accent}
                            />
                        </div>
                    )}
                </div>

                {isNotInteractive || (
                    <Button
                        aria-label={t("Close button")}
                        className={classes.closeBtn()}
                        onClick={handleHideAction}
                        onKeyDown={handleHideAction}
                        variant={EButtonVariant.EmptyTransparent}
                        size={EButtonSize.Container}
                    >
                        <CrossIconSvg width={10} height={10} strokeColor="#333333" />
                    </Button>
                )}
            </div>
        </CSSTransition>
    )
}

Box.displayName = displayName

export { Box as ParticipantCoachingMomentChatErrorBox }
