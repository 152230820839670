import {
    type ChangeEvent,
    type ForwardRefExoticComponent,
    type ForwardedRef,
    type KeyboardEvent,
    type MutableRefObject,
    type PropsWithoutRef,
    type ReactElement,
    type RefAttributes,
    forwardRef,
    useEffect,
    useRef
} from "react"

import classNames from "classnames"

import { isEmpty, isFunction } from "$/utils/gates"

import type { TEmptyCallback } from "@/shared/types/functions"

import {
    ETypographyColor,
    ETypographySize,
    ETypographyTag,
    Typography,
    typographyColorsClassMap,
    typographyLineHeightsMap,
    typographySizesClassMap
} from "../typography"

import { textareaConfig } from "./textarea.config"
import {
    ETextAreaScrollbarVariant as EScrollbarVariant,
    ETextAreaBorderWidth,
    ETextAreaVariant
} from "./textarea.types"

type TProps = {
    variant?: ETextAreaVariant
    name?: string
    defaultValue?: string
    value?: string
    textColor?: ETypographyColor
    textSize?: ETypographySize
    placeholderTextColor?: ETypographyColor
    placeholderTextSize?: ETypographySize
    placeholder?: string
    error?: string
    scrollbarVariant?: EScrollbarVariant
    onChange?(event?: ChangeEvent<HTMLTextAreaElement>): void
    onKeyDown?(event?: KeyboardEvent): void
    onFocus?: TEmptyCallback
    onBlur?: TEmptyCallback
    className?: string
    isDisabled?: boolean
    borderWidth?: ETextAreaBorderWidth
    borderRadius?: typeof textareaConfig.DEFAULT_BORDER_RADIUS
    minLines?: number
    maxLines?: number
    maxSymbols?: number
    withDynamicHeight?: boolean
    withTransparentBorder?: boolean
    withNoBorder?: boolean
    withItalicPlaceholder?: boolean
    withNoResize?: boolean
    withNoPaddings?: boolean
}

function _getTextareaLineHeightBySize(textSize: ETypographySize): number {
    return typographyLineHeightsMap[textSize]
}

const Component: ForwardRefExoticComponent<PropsWithoutRef<TProps> & RefAttributes<HTMLTextAreaElement>> = forwardRef<
    HTMLTextAreaElement,
    TProps
>(
    (
        {
            variant = ETextAreaVariant.Default,
            name = String(),
            defaultValue,
            value,
            textColor = ETypographyColor.Dark,
            textSize = ETypographySize.Medium,
            placeholderTextColor = ETypographyColor.DarkGray,
            placeholderTextSize = ETypographySize.Medium,
            placeholder = String(),
            error = String(),
            scrollbarVariant = EScrollbarVariant.Transparent,
            onChange,
            onKeyDown,
            onFocus,
            onBlur,
            className = String(),
            isDisabled = false,
            borderWidth = ETextAreaBorderWidth.Medium,
            borderRadius = textareaConfig.DEFAULT_BORDER_RADIUS,
            minLines = textareaConfig.DEFAULT_MIN_LINES,
            maxLines = textareaConfig.DEFAULT_MAX_LINES,
            maxSymbols,
            withDynamicHeight = false,
            withTransparentBorder = false,
            withNoBorder = false,
            withItalicPlaceholder = false,
            withNoResize = false,
            withNoPaddings = false,
            ...rest
        }: TProps,
        ref: ForwardedRef<HTMLTextAreaElement>
    ): ReactElement => {
        const textareaRef: MutableRefObject<HTMLTextAreaElement> = useRef<HTMLTextAreaElement>(null)

        useEffect((): void => {
            if (textareaRef.current && withDynamicHeight) {
                const textArea: HTMLTextAreaElement = textareaRef.current
                textArea.style.height = "auto"
                textArea.style.height = `${Math.min(textArea.scrollHeight, maxLines * _getTextareaLineHeightBySize(textSize))}px`
                textArea.style.overflowY =
                    textArea.scrollHeight > maxLines * _getTextareaLineHeightBySize(textSize) ? "auto" : "hidden"
            }
        }, [value, textareaRef?.current?.value, withDynamicHeight, maxLines, textSize])

        return (
            <div className={textareaConfig.classes.container}>
                {!isEmpty(name) && (
                    <label htmlFor={name} className={textareaConfig.classes.label}>
                        {name}
                    </label>
                )}
                <textarea
                    {...rest}
                    ref={(node: HTMLTextAreaElement): void => {
                        if (isFunction(ref)) ref(node)
                        else if (ref) ref.current = node
                        textareaRef.current = node
                    }}
                    className={classNames(
                        !withDynamicHeight && `min-h-[${minLines * _getTextareaLineHeightBySize(textSize)}px]`,
                        `placeholder:${typographySizesClassMap[placeholderTextSize]}`,
                        `placeholder:${typographyColorsClassMap[placeholderTextColor]}`,
                        Object.keys(borderRadius).map(
                            (key: string): string => `rounded-${key}-[${borderRadius[key]}px]`
                        ),
                        {
                            "!border-transparent": withTransparentBorder,
                            "!border-[0px]": withNoBorder,
                            "placeholder:italic": withItalicPlaceholder,
                            "resize-none": withNoResize,
                            "!p-[0px]": withNoPaddings,
                            "cursor-not-allowed": isDisabled,
                            [typographySizesClassMap[textSize]]: textSize,
                            [typographyColorsClassMap[textColor]]: textColor,
                            [textareaConfig.variantClassesMap[variant]()]: variant,
                            [textareaConfig.borderWidthMap[borderWidth]]: borderWidth,
                            [textareaConfig.scrollbarsClassesMap[scrollbarVariant](variant)]: scrollbarVariant,
                            [className]: className
                        }
                    )}
                    aria-disabled={isDisabled}
                    disabled={isDisabled}
                    tabIndex={0}
                    name={name}
                    defaultValue={defaultValue}
                    maxLength={maxSymbols}
                    value={value}
                    placeholder={placeholder}
                    rows={minLines}
                    onKeyDown={onKeyDown}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                {!isEmpty(error) && (
                    <Typography
                        text={error}
                        className={textareaConfig.classes.error}
                        tag={ETypographyTag.Span}
                        size={ETypographySize.ExtraSmall}
                        color={ETypographyColor.Error}
                    />
                )}
            </div>
        )
    }
)

Component.displayName = "Textarea"

export { Component as TextArea }
