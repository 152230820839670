import { type ForwardedRef, Fragment, type ReactElement, forwardRef, useCallback } from "react"

import { isEmpty } from "$/utils/gates"

import { ESpinnerSize, Spinner } from "@/3514/components"
import { type TEmptyRenderCallback } from "@/shared/types/functions"

import { useParticipantCoachingMomentChatContext as useChatContext } from "../context"
import { EParticipantCoachingMomentChatStatus as EChatStatus } from "../types"

import { ParticipantCoachingMomentChatErrorBox as ErrorBox } from "./participant-coaching-moment-chat-error-box.component"
import { ParticipantCoachingMomentChatFeed as Feed } from "./participant-coaching-moment-chat-feed.component"
import { ParticipantCoachingMomentChatHeading as Heading } from "./participant-coaching-moment-chat-heading.component"
import { ParticipantCoachingMomentChatInput as Input } from "./participant-coaching-moment-chat-input.component"
import { ParticipantCoachingMomentChatMessages as Messages } from "./participant-coaching-moment-chat-messages.component"
import { ParticipantCoachingMomentChatSummary as Summary } from "./participant-coaching-moment-chat-summary.component"
import { ParticipantCoachingMomentChatTypingIndicator as TypingIndicator } from "./participant-coaching-moment-chat-typing-indicator.component"
import { ParticipantCoachingMomentChatUnstartedLabel as UnstartedLabel } from "./participant-coaching-moment-chat-unstarted-label.component"

const classes = {
    container:
        "rounded-[10px] flex flex-col justify-end bg-chat-active w-full overflow-y-auto relative h-[450px] py-[5px]",
    spinner: "m-auto"
}

const Chat: ReturnType<typeof forwardRef> = forwardRef<HTMLDivElement>(
    (_, ref: ForwardedRef<HTMLDivElement>): ReactElement => {
        const {
            chat: { isChatLoading, messages, status }
        }: ReturnType<typeof useChatContext> = useChatContext()

        const areMessagesAbsent: boolean = isEmpty(messages)

        const isFeedVisible: boolean = [
            EChatStatus.Paused,
            EChatStatus.InProgress,
            EChatStatus.Ready,
            EChatStatus.GeneratingSummary,
            EChatStatus.Completed
        ].includes(status)
        const isSummaryVisible: boolean = [EChatStatus.GeneratingSummary, EChatStatus.Completed].includes(status)
        const isInputNotVisible: boolean = [EChatStatus.NotAvailable].includes(status)

        const renderHeading: TEmptyRenderCallback = useCallback((): ReactElement => <Heading />, [])

        const renderSpinner: TEmptyRenderCallback = useCallback(
            (): ReactElement => <Spinner size={ESpinnerSize.Large} wrapperClassName={classes.spinner} />,
            []
        )

        const renderUnstartedLabel: TEmptyRenderCallback = (): ReactElement =>
            areMessagesAbsent ? <UnstartedLabel /> : void 0

        const renderTypingIndicator: TEmptyRenderCallback = useCallback((): ReactElement => <TypingIndicator />, [])

        const renderErrorBox: TEmptyRenderCallback = useCallback((): ReactElement => <ErrorBox /> ?? void 0, [])

        const renderSummary: TEmptyRenderCallback = useCallback(
            (): ReactElement => (isSummaryVisible ? <Summary /> : void 0),
            [isSummaryVisible]
        )

        const renderMessages: TEmptyRenderCallback = useCallback((): ReactElement => <Messages /> ?? void 0, [])

        const renderFeed: TEmptyRenderCallback = (): ReactElement =>
            isFeedVisible ? (
                <Feed
                    renderMessages={renderMessages}
                    renderTypingIndicator={renderTypingIndicator}
                    renderErrorBox={renderErrorBox}
                    renderSummary={renderSummary}
                />
            ) : (
                void 0
            )

        const renderInput: TEmptyRenderCallback = (): ReactElement => (isInputNotVisible ? void 0 : <Input />)

        return (
            <div className={classes.container} ref={ref}>
                {isChatLoading && (
                    <Fragment>
                        {renderHeading()}
                        {renderSpinner()}
                    </Fragment>
                )}
                {!isChatLoading && (
                    <Fragment>
                        {renderHeading()}
                        {renderUnstartedLabel()}
                        {renderFeed()}
                        {renderInput()}
                    </Fragment>
                )}
            </div>
        )
    }
)

Chat.displayName = "ParticipantCoachingMomentChat"

export { Chat as ParticipantCoachingMomentChat }
