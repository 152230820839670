import type { FC, ReactElement } from "react"

type TProps = { isActive?: boolean }

const Icon: FC<TProps> = ({ isActive }: TProps): ReactElement => (
    <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.84291 7.44174C8.30662 8.733 10.4764 6.72789 10.4979 6.70737C10.5584 6.65043 10.5867 6.56748 10.5737 6.48547L9.65501 0.711192C9.64023 0.619414 9.57629 0.54321 9.48802 0.513122C9.40052 0.483766 9.303 0.504347 9.23531 0.568022C9.21624 0.586096 7.30743 2.35014 5.1569 1.22296C2.6932 -0.0675748 0.523417 1.93727 0.501754 1.9576C0.492979 1.96585 0.48645 1.97567 0.478928 1.98492C0.475898 1.98894 0.472398 1.99218 0.46963 1.9962C0.452602 2.02028 0.440534 2.04661 0.433013 2.07466C0.43202 2.0779 0.431498 2.08145 0.430766 2.08469C0.423767 2.11551 0.420737 2.14711 0.426013 2.17924L1.34467 7.95351C1.34467 7.95351 1.34467 7.95424 1.34494 7.9545L2.51057 15.2887C2.53037 15.4122 2.63666 15.5 2.75779 15.5C2.77085 15.5 2.78412 15.499 2.79739 15.497C2.93404 15.4751 3.02732 15.3468 3.00575 15.2099L1.86191 8.0124C2.2776 7.67239 3.96777 6.45841 5.84256 7.44126L5.84291 7.44174ZM0.944121 2.23659C1.36337 1.88782 3.03193 0.675515 4.92424 1.66744C6.80425 2.65282 8.51295 1.71884 9.22676 1.21185L10.0564 6.4265C9.64021 6.7665 7.94962 7.98008 6.07577 6.99764C4.19629 6.0128 2.48759 6.94624 1.77351 7.45323L0.944321 2.23658L0.944121 2.23659Z"
            fill={isActive ? "#525252" : "#737373"}
            stroke={isActive ? "#525252" : "#737373"}
            strokeWidth="0.2"
        />
    </svg>
)

Icon.displayName = "ParticipantCoachingMomentChatFeedbackIcon"

export { Icon as ParticipantCoachingMomentChatFeedbackIcon }
