import { useCallback, useMemo } from "react"

import { type MutationOptions, type UseMutationResult, useMutation } from "@tanstack/react-query"
import type { AxiosError, AxiosResponse } from "axios"

import { EParticipantCoachingMomentChatApiMutationKey as EMutationKey } from "../../../config"

import { participantCoachingMomentChatCreateMessageFetcher as fetcher } from "./participant-coaching-moment-chat.create-chat-message.fetcher"

type TParams = {
    conversationId: string
    message: string
}

type TResponse = AxiosResponse<{ message_id: number }>

type TMutation = UseMutationResult<TResponse>

function useHook(options?: MutationOptions): ReturnType<() => TMutation> {
    return useMutation<TResponse, AxiosError, TParams>(
        useMemo((): [EMutationKey] => [EMutationKey.CreateCoachingMomentChatMessage], []),
        useCallback(fetcher, []),
        // @ts-expect-error react-query types mess here, no error
        { ...options }
    )
}

export {
    useHook as useParticipantCoachingMomentChatCreateMessageMutation,
    type TMutation as TParticipantCoachingMomentChatCreateMessageMutation,
    type TParams as TParticipantCoachingMomentChatCreateMessageMutationParams,
    type TResponse as TParticipantCoachingMomentChatCreateMessageMutationResponse
}
