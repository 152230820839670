import { http } from "$/http"

import { EParticipantCoachingMomentChatApiMutationUrl as EMutationUrl } from "../../../config"

import type {
    TParticipantCoachingMomentChatCreateSeedQuestionAnswerMutationParams as TParams,
    TParticipantCoachingMomentChatCreateSeedQuestionAnswerMutationResponse as TResponse
} from "./participant-coaching-moment-chat.create-seed-question-answer.hook"

async function fetcher(params: TParams): Promise<TResponse> {
    return http.post(EMutationUrl.CreateSeedQuestionAnswer(), params)
}

export { fetcher as participantCoachingMomentChatCreateSeedQuestionAnswerFetcher }
