import {
    type Context,
    type FC,
    type ForwardedRef,
    Fragment,
    type MutableRefObject,
    type ReactElement,
    createContext,
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef
} from "react"

import { CSSTransition } from "react-transition-group"

import { isEmpty } from "$/utils/gates"

import { DynamicHeightContainer, LoadableComponent } from "@/3514/components"
import { EModalId } from "@/3514/store/slices"
import { getCssIdentifierFromFileName, getScaleAnimationCssString } from "@/3514/utils"
import { type TUseModal, useCSSInsertion, useModal } from "@/hooks"
import { type TEmptyCallback } from "@/shared/types/functions"

import {
    type TParticipantCoachingMomentChatConversationQuery as TConversationQuery,
    type TParticipantCoachingMomentChatCreateMessageMutation as TCreateMessageMutation,
    type TParticipantCoachingMomentChatCreateMessageMutationParams as TCreateMessageMutationParams,
    type TParticipantCoachingMomentChatCreateMessageMutationResponse as TCreateMessageMutationResponse,
    type TParticipantCoachingMomentChatCreateSeedQuestionAnswerMutation as TCreateSeedQuestionAnswerMutation,
    type TParticipantCoachingMomentChatCreateSeedQuestionAnswerMutationResponse as TCreateSeedQuestionAnswerMutationResponse,
    type TParticipantCoachingMomentChatCreateFeedbackMutation as TFeedbackMutation,
    useParticipantCoachingMomentChatConversationQuery as useConversationQuery,
    useParticipantCoachingMomentChatCreateMessageMutation as useCreateMessageMutation,
    useParticipantCoachingMomentChatCreateSeedQuestionAnswerMutation as useCreateSeedQuestionAnswerMutation,
    useParticipantCoachingMomentChatCreateFeedbackMutation as useFeedbackMutation,
    useParticipantCoachingMomentChatNewMessagesQuery as useNewMessagesQuery
} from "../api"
import {
    ParticipantCoachingMomentChatBanner as Banner,
    ParticipantCoachingMomentChat as Chat,
    ParticipantCoachingMomentChatFields as FieldList
} from "../components"
import {
    NEW_MESSAGES_QUERY_LONG_POLLING_REFETCH_INTERVAL_IN_MS as LONG_POLLING_INTERVAL,
    NEW_MESSAGES_QUERY_SHORT_POLLING_REFETCH_INTERVAL_IN_MS as SHORT_POLLING_INTERVAL
} from "../config"
import {
    ParticipantCoachingMomentChatAbandonmentModal as ChatAbandonmentModal,
    ParticipantCoachingMomentChatFeedbackModal as ChatFeedbackModal
} from "../modals"
import {
    type TParticipantCoachingMomentChatState as TChatState,
    type TUseParticipantCoachingMomentChatState as TUseChatState,
    useParticipantCoachingMomentChatState as useChatState
} from "../state"
import {
    EParticipantCoachingMomentChatMessageAuthor as EChatMessageAuthor,
    EParticipantCoachingMomentChatStatus as EChatStatus,
    ECoachingMomentChatModalFeedbackVariant as EModalVariant,
    type IParticipantCoachingMomentChatMessageModel as IConversationMessageModel,
    type IParticipantCoachingMomentChatNewMessagesModel as INewMessagesModel,
    type TParticipantCoachingMomentChatConfig as TChatConfig,
    type TParticipantCoachingMomentChatEntityRef as TEntityRef,
    type TParticipantCoachingMomentSeedQuestionFormField as TQuestionFormField
} from "../types"
import {
    participantCoachingMomentChatApiUtils as apiUtils,
    participantCoachingMomentChatUiUtils as uiUtils
} from "../utils"

const { getConversationStatusEnumFromType: getConversationStatus } = apiUtils
const { mapSeedQuestionsToFormFields } = uiUtils

type TChatContext = {
    form: {
        questions: TQuestionFormField[]
        handleQuestionSubmit(questionUuid: string, answer: string): Promise<void>
        createAnswerMutationStatus: Pick<TCreateSeedQuestionAnswerMutation, "status">["status"]
    }
    chat: TChatState["chat"] &
        Pick<TUseChatState, "changeStatus"> & {
            createMessageMutation: TCreateMessageMutation
        }
    modal: {
        handleModalOpen(variant: EModalVariant, message?: IConversationMessageModel): void
        handleModalClose: TEmptyCallback
        feedbackMutation: TFeedbackMutation
    }
}

type TProps = TChatConfig

const ChatContext: Context<TChatContext> = createContext<TChatContext | undefined>(undefined)

function useChatContext(): TChatContext {
    const context: TChatContext = useContext(ChatContext)

    if (!context) {
        throw new Error(
            "useParticipantCoachingMomentChatContext must be used within a ParticipantCoachingMomentChatProvider"
        )
    }

    return context
}

const displayName: string = "ParticipantCoachingMomentChatProvider"

const chatAnimationCssIdentifier: string = getCssIdentifierFromFileName(displayName)

const ChatProvider: FC<TProps> = forwardRef(
    (
        {
            seedQuestions,
            isChatAvailable,
            chapterId,
            chapterComponentId,
            conversationId: existingConversationId,
            onChatComplete
        }: TProps,
        entityRef: ForwardedRef<TEntityRef>
    ): ReactElement => {
        const {
            state: { chat: chatState, form: formState },
            addNewMessages,
            setQuestionAnswer,
            loadQuestionsAnswers,
            setConversationId,
            loadConversation,
            triggerError,
            changeStatus,
            sendMessage,
            removeMessage
        }: TUseChatState = useChatState()

        useEffect(
            (): void => (
                !isEmpty(seedQuestions) && loadQuestionsAnswers(mapSeedQuestionsToFormFields(seedQuestions)),
                !isEmpty(existingConversationId) && setConversationId(existingConversationId),
                !isChatAvailable && changeStatus(EChatStatus.NotAvailable),
                void 0
            ),
            [
                existingConversationId,
                isChatAvailable,
                seedQuestions,
                changeStatus,
                setConversationId,
                loadQuestionsAnswers
            ]
        )

        const createMessageMutation: TCreateMessageMutation = useCreateMessageMutation({
            // @ts-expect-error no error here, just types mess
            onMutate: async ({
                conversationId: createdMessageConversationId,
                message: createdMessageText
            }: TCreateMessageMutationParams): Promise<{
                optimisticMessage: IConversationMessageModel
            }> => {
                const optimisticMessage: IConversationMessageModel = {
                    id: Date.now(),
                    conversationId: Number(createdMessageConversationId),
                    message: createdMessageText,
                    author: EChatMessageAuthor.User,
                    conversation: null
                }

                sendMessage({ message: optimisticMessage })

                return { optimisticMessage }
            },
            onSuccess: async (
                response: TCreateMessageMutationResponse,
                _vars: void,
                context: { optimisticMessage: IConversationMessageModel }
            ): Promise<void> =>
                sendMessage({
                    responseMessageId: response.data.message_id,
                    optimisticMessageId: context.optimisticMessage.id
                }),
            onError: async (
                _err: unknown,
                _vars: void,
                context: { optimisticMessage: IConversationMessageModel }
            ): Promise<void> => (console.log(_err), removeMessage(context.optimisticMessage.id), triggerError())
        })

        const {
            mutateAsync: handleCreateSeedQuestionAnswer,
            status: createSeedQuestionAnswerMutationStatus
        }: TCreateSeedQuestionAnswerMutation = useCreateSeedQuestionAnswerMutation({
            retry: false,
            onSuccess: async ({ data: responseData }: TCreateSeedQuestionAnswerMutationResponse): Promise<void> => (
                !isEmpty(responseData)
                    ? loadConversation({
                          conversation: {
                              id: responseData.id,
                              summary: responseData.summary,
                              status: getConversationStatus(responseData.status as never),
                              completedStamp: responseData.completed_stamp,
                              messages: []
                          },
                          status: EChatStatus.InProgress
                      })
                    : loadQuestionsAnswers(mapSeedQuestionsToFormFields(seedQuestions)),
                void 0
            )
        })

        const { data: chatConversationData }: TConversationQuery = useConversationQuery({
            conversationId: chatState.conversationId,
            enabled: !isEmpty(chatState.conversationId) && createSeedQuestionAnswerMutationStatus === "idle",
            retry: false,
            staleTime: chatState.status !== EChatStatus.Completed ? 0 : Infinity,
            refetchOnMount: chatState.status !== EChatStatus.Completed ? "always" : false,
            onError: async (): Promise<void> => triggerError()
        })

        useNewMessagesQuery({
            conversationId: chatState.conversationId,
            enabled: [EChatStatus.InProgress].includes(chatState.status) && !chatState.hasError,
            retry: false,
            refetchInterval: isEmpty(chatState.messages)
                ? SHORT_POLLING_INTERVAL
                : chatState.messages[chatState.messages.length - 1]?.author === EChatMessageAuthor.User
                  ? SHORT_POLLING_INTERVAL
                  : LONG_POLLING_INTERVAL,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: async (fetchedMessages: INewMessagesModel): Promise<void> => addNewMessages(fetchedMessages),
            onError: async (): Promise<void> => triggerError()
        })

        const feedbackMutation: TFeedbackMutation = useFeedbackMutation({
            retry: false,
            networkMode: "offlineFirst"
        })

        useEffect(
            (): void => (
                !isEmpty(chatConversationData) && loadConversation({ conversation: chatConversationData }), void 0
            ),
            [chapterId, chatConversationData, chatState.conversationId, loadConversation]
        )

        useEffect(
            (): void => (chatState.status === EChatStatus.GeneratingSummary && onChatComplete(), void 0),
            [chatState.status, onChatComplete]
        )

        const handleSeedQuestionAnswerSubmit: (questionUuid: string, answer: string) => Promise<void> = useCallback(
            async (questionUuid: string, answer: string): Promise<void> => (
                await handleCreateSeedQuestionAnswer(
                    { question_uuid: questionUuid, answer, chapter_progress: chapterId, component: chapterComponentId },
                    {
                        onSuccess: async (): Promise<void> => setQuestionAnswer({ id: questionUuid, answer })
                    }
                ),
                void 0
            ),
            [chapterComponentId, chapterId, handleCreateSeedQuestionAnswer, setQuestionAnswer]
        )

        const { showModal, hideModal, getModal }: TUseModal = useModal()

        useImperativeHandle(
            entityRef,
            (): TEntityRef => ({
                openAbandonmentModal: (onConfirm: TEmptyCallback): void =>
                    [EChatStatus.InProgress, EChatStatus.Ready].includes(chatState.status)
                        ? showModal(EModalId.CoachingChatAbandonment, { onConfirm })
                        : onConfirm()
            }),
            [chatState.status, showModal]
        )

        const handleFeedbackModalOpen: (variant: EModalVariant, message?: IConversationMessageModel) => void =
            useCallback(
                (variant: EModalVariant, message?: IConversationMessageModel): void =>
                    showModal(EModalId.CoachingChatFeedback, { variant, message }),
                [showModal]
            )

        const handleFeedbackModalClose: TEmptyCallback = useCallback(
            (): void => hideModal(EModalId.CoachingChatFeedback),
            [hideModal]
        )

        const providerValue: ReturnType<typeof useChatContext> = useMemo(
            (): TChatContext => ({
                form: {
                    questions: formState.questions,
                    handleQuestionSubmit: handleSeedQuestionAnswerSubmit,
                    createAnswerMutationStatus: createSeedQuestionAnswerMutationStatus
                },
                chat: {
                    ...chatState,
                    changeStatus,
                    createMessageMutation
                },
                modal: {
                    handleModalOpen: handleFeedbackModalOpen,
                    handleModalClose: handleFeedbackModalClose,
                    feedbackMutation
                }
            }),
            [
                formState.questions,
                handleSeedQuestionAnswerSubmit,
                createSeedQuestionAnswerMutationStatus,
                chatState,
                changeStatus,
                createMessageMutation,
                handleFeedbackModalOpen,
                handleFeedbackModalClose,
                feedbackMutation
            ]
        )

        const chatRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>()

        useCSSInsertion({
            cssString: getScaleAnimationCssString({
                identifier: chatAnimationCssIdentifier,
                durationInMs: 400,
                scaleTo: 0.9
            })
        })

        return (
            <ChatContext.Provider value={providerValue}>
                <Fragment>
                    <FieldList />

                    {chatState.status === EChatStatus.Locked ? <Banner /> : null}

                    <CSSTransition
                        timeout={400}
                        nodeRef={chatRef}
                        classNames={chatAnimationCssIdentifier}
                        in={
                            isEmpty(existingConversationId)
                                ? chatState.status !== EChatStatus.NotAvailable &&
                                  chatState.status !== EChatStatus.Locked
                                : chatState.status !== EChatStatus.NotAvailable
                        }
                        mountOnEnter
                        unmountOnExit
                    >
                        <DynamicHeightContainer timeoutInMs={2000}>
                            <Chat ref={chatRef} />
                        </DynamicHeightContainer>
                    </CSSTransition>
                </Fragment>

                <LoadableComponent
                    isReady={!isEmpty(getModal(EModalId.CoachingChatAbandonment))}
                    component={ChatAbandonmentModal}
                />

                <LoadableComponent
                    isReady={!isEmpty(getModal(EModalId.CoachingChatFeedback))}
                    component={ChatFeedbackModal}
                />
            </ChatContext.Provider>
        )
    }
)

ChatProvider.displayName = displayName

export {
    ChatProvider as ParticipantCoachingMomentChatProvider,
    useChatContext as useParticipantCoachingMomentChatContext
}
