import type { QueryFunctionContext } from "@tanstack/query-core"
import type { AxiosResponse } from "axios"

import { http } from "$/http"

import Urls from "@/api/urls"
import { ServerStateKeys } from "@/constants"
import type { IChapterDto } from "@/pages/participant-chapter/api/dto"

async function fetcher({ queryKey }: QueryFunctionContext<[ServerStateKeys, string]>): Promise<IChapterDto> {
    const [, id] = queryKey
    const { data }: AxiosResponse<IChapterDto> = await http.get(Urls.getChapterById(id))

    return data
}

export { fetcher as chapterByIdQueryFetcher }
