import { type FC, type ReactElement } from "react"

import classNames from "classnames"

import { ETypographyColor, ETypographyFontWeight, ETypographySize, Typography } from "@/3514/components"
import { type IUseTranslation, useTranslation } from "@/hooks"

import { useParticipantCoachingMomentChatContext as useChatContext } from "../context"
import { EParticipantCoachingMomentChatStatus as EChatStatus } from "../types"

const classes: { container(isVisible: boolean): string; hr(): string; text(): string } = {
    container: (isVisible: boolean): string =>
        classNames(
            ["pt-[7px]", "pb-[30px]", "px-[20px]", "flex", "items-center", "gap-x-[10px]", "w-full", "bg-transparent"],
            isVisible || "hidden"
        ),
    hr: (): string => "bg-white w-full h-[1px] m-[0px]",
    text: (): string => "text-shadow-chat-completed min-w-max"
}

const Indicator: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const {
        chat: { status }
    }: ReturnType<typeof useChatContext> = useChatContext()

    const isVisible: boolean = status === EChatStatus.Completed || status === EChatStatus.GeneratingSummary

    return (
        <div className={classes.container(isVisible)}>
            <hr className={classes.hr()} />
            <Typography
                text={t("participantSide.chapter.coachingMoment.chat.chatClosedMessage")}
                color={ETypographyColor.White}
                size={ETypographySize.ExtraSmall}
                weight={ETypographyFontWeight.Bold}
                className={classes.text()}
            />
            <hr className={classes.hr()} />
        </div>
    )
}

Indicator.displayName = "ParticipantCoachingMomentChatClosedIndicator"

export { Indicator as ParticipantCoachingMomentChatClosedIndicator }
