import { useCallback, useMemo } from "react"

import { type MutationOptions, type UseMutationResult, useMutation } from "@tanstack/react-query"
import type { AxiosError, AxiosResponse } from "axios"

import { EParticipantCoachingMomentChatApiMutationKey as EMutationKey } from "../../../config"

import { participantCoachingMomentChatCreateSeedQuestionAnswerFetcher as fetcher } from "./participant-coaching-moment-chat.create-seed-question-answer.fetcher"

type TParams = {
    question_uuid: string
    answer: string
    chapter_progress: number
    component: number
}

type TResponse = AxiosResponse<{
    id: number
    status: string
    completed_stamp: null | string | undefined
    summary: null | string
} | null>

type TMutation = UseMutationResult<TResponse>

function useHook(options?: MutationOptions): ReturnType<() => TMutation> {
    return useMutation<TResponse, AxiosError, TParams>(
        useMemo((): [EMutationKey] => [EMutationKey.CreateCoachingMomentChatSeedQuestionAnswer], []),
        useCallback(fetcher, []),
        // @ts-expect-error react-query types mess here, no error
        { ...options }
    )
}

export {
    useHook as useParticipantCoachingMomentChatCreateSeedQuestionAnswerMutation,
    type TMutation as TParticipantCoachingMomentChatCreateSeedQuestionAnswerMutation,
    type TParams as TParticipantCoachingMomentChatCreateSeedQuestionAnswerMutationParams,
    type TResponse as TParticipantCoachingMomentChatCreateSeedQuestionAnswerMutationResponse
}
