import type { FC, ReactElement } from "react"

import { ParticipantCoachingMomentChatOwlLogoImage as DefaultLogoImage } from "../assets"

const classes: { wrapper: string; img: string } = {
    wrapper: "pt-[17px] px-[3px] bg-red-400 rounded-[20px] min-w-[30px] h-[30px] overflow-hidden",
    img: "relative bottom-[10px]"
}

const DefaultLogo: FC = (): ReactElement => (
    <div className={classes.wrapper}>
        <DefaultLogoImage className={classes.img} />
    </div>
)

const logos = { DefaultLogo }

export { logos as participantCoachingMomentOwlComponents }
