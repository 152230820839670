import { type FC, type LazyExoticComponent, lazy } from "react"

const ParticipantCoachingMomentChatAbandonmentModal: LazyExoticComponent<FC> = lazy(
    (): Promise<{ default: FC }> =>
        import("./participant-coaching-moment-chat-abandonment-modal-container.component").then(
            ({ ParticipantCoachingMomentChatAbandonmentModalContainer: C }): { default: FC } => ({
                default: C
            })
        )
)

export { ParticipantCoachingMomentChatAbandonmentModal }
